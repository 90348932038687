import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormattedResponse } from '../models/formatted-response.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../interceptors/token-interceptor.service';

const BASE_URL = `${environment.api_URL}`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class CostManagementService {
    constructor(private http: HttpClient) { }

    getCost(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/costManagement`);
    }

    getCostById(id: number): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/costManagement/${id}`);
    }

    getCostTable(params: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/costManagementTable`, { params, context });
    }

    addCost(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/costManagement`, data );
    }

    updateCost(id: number, data): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/costManagement/${id}`, { data });
    }

    deleteCost(id: number): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/costManagement/${id}`);
    }
}