import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormattedResponse } from '../models/formatted-response.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../interceptors/token-interceptor.service';

const BASE_URL = `${environment.api_URL}`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class ContabilitaService {
    constructor(private http: HttpClient) { }

    getInput(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getInput`, { params });
    }

    getAllInput(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAllInput`);
    }

    getTable(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getTable`, { params, context });
    }

    addInput(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/insertInput`, data);
    }

    updateInput(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/updateInput`, data);
    }

    deleteInput(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteInput`, { params });
    }

    // OUTPUT

    getOutput(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getOutput`, { params });
    }

    getAllOutput(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAllOutput`);
    }

    getOutTable(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getTableUscite`, { params });
    }

    addOutput(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/inserOutput`, data);
    }

    updateOutput(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/updateOutput`, data);
    }

    deleteOutput(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteOutput`, { params });
    }
}