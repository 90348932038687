<form [formGroup]="userForm">
    <div>
        <h3 *ngIf="!detail"> Dati personali </h3>
        <div class="row">
            <div class="col-4">
                <app-input [form]="userForm" [control]="'name'" [type]="'input'" [label]="'Nome'"></app-input>
            </div>
            <div class="col-4">
                <app-input [form]="userForm" [control]="'surname'" [type]="'input'" [label]="'Cognome'"></app-input>
            </div>
            <div class="col-4">
                <app-input [form]="userForm" [control]="'role'" [type]="'select'" [label]="'Ruolo'" [values]="roles" [readonly]="detail && checkIncludedFields(userForm, 'role', [5, 6], true)"></app-input>
            </div>

            <div class="col-4">
                <app-input [form]="userForm" [control]="'phone'" [type]="'input'" [label]="'Telefono'" [inputType]="'number'"></app-input>
            </div>

            <div class="col-4">
                <app-input [form]="userForm" [control]="'social'" [type]="'select'" [label]="'Canali di conoscenza'" [values]="channels"></app-input>
            </div>

            <div class="col-4" *ngIf="checkIncludedFields(userForm, 'role', [6], true)">
                <app-input [form]="userForm" [control]="'birth_date'" [type]="'date'" [label]="'Data di nascita'"></app-input>
            </div>
            
            <ng-container *ngIf="checkIncludedFields(userForm, 'role', [5, 6])">
                <div class="col-3">
                    <app-input [form]="userForm" [control]="'address'" [type]="'input'" [label]="'Indirizzo'"></app-input>
                </div>
                <div class="col-1">
                    <app-input [form]="userForm" [control]="'civico'" [type]="'input'" [label]="'Civico'"></app-input>
                </div>
                <div class="col-4">
                    <app-input [form]="userForm" [control]="'city'" [type]="'input'" [label]="'Città'"></app-input>
                </div>
                <div class="col-4">
                    <app-input [form]="userForm" [control]="'cap'" [type]="'input'" [label]="'Cap'" [inputType]="'number'"></app-input>
                </div>

                <div class="col-4">
                    <app-input [form]="userForm" [control]="'fiscal_code'" [type]="'input'" [label]="'Codice Fiscale'"></app-input>
                </div>
                <div class="col-4">
                    <app-input [form]="userForm" [control]="'p_iva'" [type]="'input'" [label]="'Partita Iva'"></app-input>
                </div>
                <div class="col-4">
                    <app-input [form]="userForm" [control]="'unique_code'" [type]="'input'" [label]="'Codice Univoco'"></app-input>
                </div>
            </ng-container>

            <div class="col-4" *ngIf="checkIncludedFields(userForm, 'role', [2, 3, 4], true)">
                <app-input [form]="userForm" [control]="'category'" [type]="'select'" [label]="'Categoria'" [values]="categories"></app-input>
            </div>

            <div class="col-4" *ngIf="checkIncludedFields(userForm, 'role', [5])">
                <app-input [form]="userForm" [control]="'iscritto'" [type]="'select'" [label]="'Interessati / iscritti'" [values]="subscriptions"></app-input>
            </div>

            <div class="col-4" *ngIf="userForm.get('iscritto').value == 1">
                <app-input [form]="userForm" [control]="'registration_date'" [type]="'date'" [label]="'Data di iscrizione'"></app-input>
            </div>

            <div class="col-4" *ngIf="checkIncludedFields(userForm, 'role', [2, 3, 4])">
                <app-input [form]="userForm" [control]="'color_bg'" [type]="'input'" [label]="'Colore tooltip'" [inputType]="'color'"></app-input>
            </div>

            <div class="col-4" *ngIf="detail || checkIncludedFields(userForm, 'role', [2, 3, 4], true)">
                <app-input [form]="userForm" [control]="'email'" [type]="'input'" [label]="'Email'"></app-input>
            </div>
        </div>
        
        <div class="row">
            <div class="col-4">
                <app-input [form]="userForm" [control]="'privacy'" [type]="'checkbox'" [label]="'Consenso alla privacy'"></app-input>
            </div>
            <div class="col-4">
                <app-input [form]="userForm" [control]="'email_promozionali'" [type]="'checkbox'" [label]="'Consenso email promozionali'"></app-input>
            </div>
            <div class="col-4">
                <app-input [form]="userForm" [control]="'consenso_immagini'" [type]="'checkbox'" [label]="'Consenso immagini'"></app-input>
            </div>
        </div>

        <div class="row mt-5" *ngIf="!detail && checkIncludedFields(userForm, 'role', [2, 3, 4])">
            <h3> Credenziali registrazione </h3>
            <div class="col-4">
                <app-input [form]="userForm" [control]="'email'" [type]="'input'" [label]="'Email'"></app-input>
            </div>
            <div class="col-4">
                <app-input [form]="userForm" [control]="'password'" [type]="'input'" [label]="'Password'" [inputType]="'password'"></app-input>
            </div>
            <div class="col-4">
                <app-input [form]="userForm" [control]="'password_confirmation'" [type]="'input'" [label]="'Ripeti password'" [inputType]="'password'"></app-input>
            </div>

            <password-criterias [control]="userForm.get('password')"></password-criterias>
        </div>
    </div>
</form>