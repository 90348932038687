import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ClasseService } from 'src/app/services/class.service';
import { isAdminOrStaff } from 'src/app/utilities/users-utilities';

@Component({
  selector: 'app-classi',
  templateUrl: './classi.component.html',
  styleUrls: ['./classi.component.scss']
})
export class ClassiComponent implements OnInit, OnDestroy {
  adminOrStaff = isAdminOrStaff();
  @ViewChild('confirmModalEdit') confirmModalEdit;

  filter: any;
  data: any;
  time: any;
  displayedColumns: string[] = ['nome_classe', 'lingua_corso', 'frequenza', 'livello', 'azione'];
  stato = [{ id: "PRESENZA", value: "PRESENZA" }, { id: "ONLINE", value: "ONLINE" }];
  currentPage = 0;
  pageSize = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  classes: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  classForm: FormGroup;
  classEditForm: FormGroup;
  pageEvent: PageEvent;
  deleteContent: any;
  tempForm: FormGroup;
  levels = new Array();
  tutti = { id: 0, nome: 'Tutti' };
  languages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  eta = ['Kids', 'Teen', 'Adult'];
  nome_livello = ['Intero livello', 'Mezzo livello'];
  filter_level: any;
  user_id: string;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  today: any;
  allCertifications: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  arrayLingue: any;
  private inputTimeout: any;
  private destroy$: Subject<void> = new Subject<void>();

  tipo_vanzamento = [
    { lable: "General Language", value: "General Language" },
    { lable: "Conversation", value: "Conversation" },
    { lable: "Exam Preparation", value: "Exam Preparation" },
    { lable: "Specific Purposes", value: "Specific Purposes" },
    { lable: "YL", value: "YL" },
    { lable: "YL Exam Preparation", value: "YL Exam Preparation" },
    { lable: "Other", value: "Other" }];

  constructor(
    private fb: FormBuilder,
    private _classeService: ClasseService,
    private _anagraficaService: AnagraficaService,
    private toastr: ToastrService,
    private router: Router,
    private dateAdapter: DateAdapter<Date>,
    private cdr: ChangeDetectorRef
  ) {
    this.dateAdapter.setLocale('it-IT');
    this.today = new Date().toISOString().split("T")[0];


    this.classForm = this.fb.group({
      id: [null],
      nome_classe: [null, Validators.required],
      class_code: [null],
      certificazioni: [null],
      materiale_didattico: [null],
      tipo_avanzamento: [null],
      scopi_corso: [null],
      data_inizio: [moment().format('YYYY-MM-DD')],
      data_fine: [moment().format('YYYY-MM-DD')],
      lingua_corso: [null, Validators.required],
      livello_id: [null, Validators.required],
      eta: [null, Validators.required],
      nome_livello: [null],
      stato: [this.stato[0].id],
      monte_ore: [null],
      durata_lezione: [null],
      numero_lezioni: [null],
      periodo: [null],
      frequenza: this.fb.array([])
    });

    this.tempForm = this.fb.group({
      giorno: null,
      ora: null,
      minuti: null
    })


    this.classEditForm = this.fb.group({
      id: [null],
      nome_classe: [null, Validators.required],
      class_code: [null],
      certificazioni: [null],
      materiale_didattico: [null],
      tipo_avanzamento: [null],
      scopi_corso: [null],
      data_inizio: [moment().format('YYYY-MM-DD')],
      data_fine: [moment().format('YYYY-MM-DD')],
      lingua_corso: [null, Validators.required],
      livello_id: [null, Validators.required],
      eta: [null, Validators.required],
      nome_livello: [null],
      stato: [null],
      monte_ore: [null],
      durata_lezione: null,
      numero_lezioni: null,
      periodo: null,
      frequenza: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.user_id = localStorage.getItem('user_id');
    this._anagraficaService.getUserId({ user_id: this.user_id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.ruolo.next(res.result?.role);
    });
    $('.tooltipevent').remove();
    this.getData();
    this.getLevel();
    this.getLanguages();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetClass() {
    this.classForm.reset();
  }

  getData() {
    let params;
    params = { start: this.currentPage * this.pageSize, length: this.pageSize };
    if (this.filter) {
      params = { start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter };
      if (this.filter_level) {
        params = { start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter, filter_level: this.filter_level };
      }
    } else if (this.filter_level) {
      params = { start: this.currentPage * this.pageSize, length: this.pageSize, filter_level: this.filter_level };
    }

    this._classeService.getTable(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.classes.next(response.result);
        this.data = new MatTableDataSource(response.result);
        this.records.next(response.total);
      }
    });
  }

  getLevel() {
    this._classeService.getLevel().pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.levels = response.result;
        this.classForm.patchValue({
          livello_id: response.result[0].id
        })
      }
    });
  }

  getLanguages() {
    this._classeService.getLanguage().pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.languages.next(response.result);
      this.arrayLingue = response.result;
      this.classForm.patchValue({
        lingua_corso: response.result[0].nome_lingua
      })
      this._classeService.getAllCertifications({ lingua_id: this.arrayLingue[0].id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.allCertifications.next(res.result);
        let obj = { id: "Nessuna", nome: "Nessuna" };
        this.allCertifications.value.unshift(obj);
      });
    })
  }

  getAllCertifications(event, callback?: () => void): void {
    let id: number;
    if (event?.target?.value) {
      id = this.arrayLingue.find(el => el.nome_lingua === event.target.value).id
    } else {
      id = this.arrayLingue.find(el => el.nome_lingua === event).id
    }

    this._classeService.getAllCertifications({ lingua_id: id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.allCertifications.next(res.result);
      let obj = { id: "Nessuna", nome: "Nessuna" };
      this.allCertifications.value.unshift(obj);
      this.cdr.detectChanges();

      if (callback) {
        callback();
      }
    });
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  openConfirmModal() {
    this.confirmModalEdit.nativeElement.click();
  }

  closeConfirmModal() {
    $('#confirmModal').hide();
  }

  addClass() {
    if (this.classForm.value != null) {
      this._classeService.addClass(this.classForm.value).pipe(takeUntil(this.destroy$)).subscribe(
        response => {
          if (response.status == true) {
            //METTERE IL TOST E FARE REFRESH//
            this.classForm.reset();
            this.toastr.success('Classe aggiunta con successo');
            this.getData();
            this.goTo('class-details/' + response.result.id)
          } else {
            this.toastr.error(response.message);
          }
        }
      );
      // }
    }
  }

  setEdit(classe: any) {
    if (!this.classEditForm.contains('certificazioni')) {
      console.error('Campo certificazioni mancante nel form');
      return;
    }

    this.getAllCertifications(classe.lingua_corso, () => {
      this.classEditForm.patchValue({
        id: classe.id,
        nome_classe: classe.nome_classe,
        data_inizio: this.getFormattedDate(classe.data_inizio, 'YYYY-MM-DD'),
        data_fine: this.getFormattedDate(classe.data_fine, 'YYYY-MM-DD'),
        lingua_corso: classe.lingua_corso,
        livello_id: classe.livello_id,
        eta: classe.eta,
        class_code: classe.class_code,
        certificazioni: classe.certificazioni,
        materiale_didattico: classe.materiale_didattico,
        tipo_avanzamento: classe.tipo_avanzamento,
        scopi_corso: classe.scopi_corso,
        stato: classe.stato,
        monte_ore: classe.monte_ore,
        durata_lezione: classe.durata_lezione,
        numero_lezioni: classe.numero_lezioni,
        periodo: classe.periodo
      });
      console.log('formEdit', this.classEditForm.value);
      this.cdr.detectChanges();
    });
  }

  resetEditForm() {
    this.classEditForm.reset();
  }

  editClass() {
    $('#confirmModal').hide();

    var modalEditClass = document.getElementById('modalEditClass');
    modalEditClass.style.display = 'none';
    $('.modal-backdrop').hide();
    this._classeService.updateClass(this.classEditForm.value).pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.status == true) {
          this.toastr.success('Classe aggiornata con successo');

          this.getData();
        }
        else {
          this.toastr.error(response.message);
        }
      }
    )
  }

  setClass(classe: any) {
    if (this.deleteContent) {
      this.deleteContent = null;
      this.deleteContent = classe;
    } else {
      this.deleteContent = classe;
    }
    this.ngOnInit();
  }

  deleteClass(id: any) {
    this._classeService.deleteClass({ id: id }).pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response) {
          this.toastr.success('Classe rimossa con successo');
          //ELIMINO TUTTI GLI EVENTI NEL CALENDARIO ASSOCIATI//
          this._classeService.deleteAllEventByClass({ classe_id: id }).pipe(takeUntil(this.destroy$)).subscribe(response => { });
          this.ngOnInit();
        }
      }
    )
  }

  setFilter(filtro) {
    this.filter_level = filtro.target.value;
    this.getData();
  }

  goTo(string: any) {
    this.router.navigate([string]);
  }

  goToWithId(string: any, id: any) {
    this.router.navigate([string, id]);
  }
}