<div class="altezza-page px-0 mx-0" id="wrap2">
    <div class="row px-0 mx-0">
        <div class="col-md-2 px-0 mx-0">
            <aside class="bg-color">
                <div class="margine-sup-side padding-logo mb-4">
                    <a routerLink="/dashboard">
                        <img alt="Logo" class="h-25px logo" src="./assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>

                <ng-container *ngFor="let item of sidebar_items">
                    <div class="menu-item" (click)="filterRole(item.value)" onkeydown="">
                        <a class="menu-link without-sub style-link">
                            <span class="menu-icon">
                                <span [inlineSVG]="item.icon" class="svg-icon svg-icon-2"></span>
                            </span>
                            <span class="menu-title">{{item.label}}</span>
                        </a>
                    </div>
                </ng-container>
            </aside>
        </div>

        <div class="col-md-10 margine-sup">
            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0"> Anagrafica
                            <span *ngIf="url_role_id">{{getSelectedElement()}}</span>
                        </h1>
                    </div>
                </div>
            </div>

            <!-- FILTRI E TABELLA  -->
            <div class="section__body">
                <div class="row mt-4 mb-3 justify-content-between align-items-center">
                    <div class="col-3">
                        <app-input [form]="filtersForm" [control]="'search'" [type]="'input'"
                            [placeholder]="'Cerca...'"></app-input>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <button *ngIf="(records | async) > 0" class="btn btn-outline-success me-3"
                            style="border: 1px solid #28a745;" (click)="exportToExcel()">
                            Esporta in Excel
                            <img alt="excel-icon" src="/assets/img/file-type-excel.svg" height="20px" width="auto">
                        </button>
                        <button type="button" class="btn color-butt" data-toggle="modal" data-target="#userModal"
                            (click)="openModal()" *ngIf="adminOrStaff">
                            Aggiungi
                            <span *ngIf="!url_role_id; else dynamicUser">utente</span>
                            <ng-template #dynamicUser>{{getSelectedElement()}}</ng-template>
                        </button>
                    </div>
                </div>

                <!-- FILTRI -->
                <div class="row mb-4" *ngIf="url_role_id != 6">
                    <div class="col">
                        <app-input [form]="filtersForm" [control]="'age'" [type]="'select'" [label]="'Target età'"
                            [values]="getSelectWithAllField(categories)"></app-input>
                    </div>

                    <div class="col">
                        <app-input [form]="filtersForm" [control]="'subscribed'" [type]="'select'"
                            [label]="'Iscritti e interessati'"
                            [values]="getSelectWithAllField(subscriptions)"></app-input>
                    </div>

                    <div class="col">
                        <app-input [form]="filtersForm" [control]="'language'" [type]="'select'" [label]="'Lingua'"
                            [values]="getSelectWithAllField(languages)"></app-input>
                    </div>

                    <div class="col">
                        <app-input [form]="filtersForm" [control]="'level'" [type]="'select'" [label]="'Livello'"
                            [values]="getSelectWithAllField(levels)"></app-input>
                    </div>
                </div>

                <div *ngIf="(records | async) == 0">
                    <div class="no-cont py-4"><i class="f-16">Nessun risultato riscontrato</i> </div>
                </div>

                <table *ngIf="(records | async) > 0" mat-table class="box_super tb-bord" [dataSource]="members | async">

                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Nome </th>
                        <td mat-cell *matCellDef="let element">{{element.name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="cognome">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Cognome </th>
                        <td mat-cell *matCellDef="let element">{{element.surname}}</td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Email </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.email ? element.email :"Non presente"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="data_nascita">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Data di nascita
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.birth_date ? (element.birth_date| date:'dd/MM/YYYY') :"Non presente"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="data_iscrizione">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Data di
                            iscrizione
                            <button mat-icon-button (click)="toggleOrder('registration_date')">
                                <mat-icon>{{ order_date === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
                            </button>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.registration_date ? (element.registration_date | date:'dd/MM/YYYY') :"Non presente"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ruolo">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Ruolo </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.ruolo_utente.display_name}}
                        </td>
                    </ng-container>

                    <ng-container *ngIf="url_role_id == 6" matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Telefono </th>
                        <td mat-cell *matCellDef="let element">{{element.phone}}</td>
                    </ng-container>

                    <ng-container matColumnDef="azione">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga large">
                            <span *ngIf="adminOrStaff"> Azione </span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="large">
                            <button class="btn btn-outline-dark" (click)="goToUser(element.id)" *ngIf="adminOrStaff">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                </svg>
                            </button>
                            <button class="btn btn-outline-danger" data-toggle="modal" (click)="deleteContent = element"
                                *ngIf="adminOrStaff" data-target="#deleteMember">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                </svg>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="url_role_id != 6 ? displayedColumns : genitoreDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: url_role_id != 6 ? displayedColumns : genitoreDisplayedColumns;" onkeydown=""></tr>
                </table>

                <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                    (page)="pageEvent = handlePage($event)" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>

<!-- CREAZIONE UTENTE  -->
<div class="modal text-left fade" id="userModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xxl">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2>Aggiungi Utente</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="userForm.reset()"></button>
            </div>
            <div class="modal-body">
                <app-user-form [userForm]="userForm" [roles]="roles" [channels]="channels"
                    [categories]="categories" [subscriptions]="subscriptions"></app-user-form>

                <div class="d-flex justify-content-between align-items-center">
                    <p><i>I campi contrassegnati <span style="color: red">*</span> sono obbligatori</i></p>

                    <div>
                        <button class="d-none" type="button" #closeModalButton data-dismiss="modal"></button>
                        <button type="button" class="btn color-butt my-3" (click)="addUser()"
                            [disabled]="!userForm.valid">
                            Aggiungi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ELIMINAZIONE UTENTE  -->
<div class="modal fade bd-example-modal-sm" id="deleteMember" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina utente</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Rimuovere definitivamente {{deleteContent?.name}} {{deleteContent?.surname}}?</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteMember(deleteContent.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>