import { Injectable } from '@angular/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from '../modules/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../modules/auth/services/login.service';
import { LoaderService } from '../shared/helpers/loader.service';
import { HttpContextConfig } from '../models/http-context-config';

export const CONFIG = new HttpContextToken<HttpContextConfig>(() => new HttpContextConfig());

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router,
    private loaderService: LoaderService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('user_token');
    const route = this.router.url;

    const helper = new JwtHelperService();
    const isExpired = token ? helper.isTokenExpired(token) : true;

    if (isExpired && route !== '/auth/registration' && route !== '/auth/login') {
      localStorage.clear();
      this.loginService.logout();
      this.router.navigate(['/auth/login']);
      return throwError(() => new Error('Session expired'));
    }

    if (route !== '/auth/login' && route !== '/auth/registration') {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }

    if (request.context.get(CONFIG)) {
      const config = request.context.get(CONFIG);
      if (config && !config.noSpinner) {
        this.loaderService.setLoading(true, request.url);
      }

      if (config.isPublic === false) {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };

          request = request.clone({
            setHeaders: headers,
          });
        }
      }
    }

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          // handle successful response
          // console.log("Request successful");
        }
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.loginService.logout();
          this.router.navigate(['/auth/login']);
        }

        return throwError(err.error);
      }),
      finalize(() => {
        this.loaderService.setLoading(false, request.url);
      })
    );
  }
}
