// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  api_URL: 'services/api',
  production: true,
  appVersion: 'v8.0.34',
  USERDATA_KEY: 'authf649fc9a5f55'
};
