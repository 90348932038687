<div>
    <form [formGroup]="registerForm">
        <div class="table-container">
            <div *ngIf="(register | async)?.length == 0">
                <div class="no-cont py-4"><i class="f-16">Registro non ancora presente, crea le lezioni per
                        popolarlo</i> </div>
            </div>
            <table *ngIf="(register | async)?.length > 0" mat-table class="box_super tb-bord" [dataSource]="register">

                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-date">Data</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-date">{{element.giorno | date:
                        'dd/MM/yyyy'}}</td>
                </ng-container>


                <ng-container matColumnDef="docente">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-student">Docente</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-student">
                        {{element.evento_rel.docente.name}} {{element.evento_rel.docente.surname}}</td>
                </ng-container>

                <ng-container matColumnDef="lezione">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-student">Lezione</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-student">
                        <div *ngIf="!element.edit">
                            {{element.lezione}}
                        </div>
                        <div *ngIf="element.edit">
                            <input type="text" formControlName="lezione" class="form-control">
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-student">Unit</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-student">
                        <div *ngIf="!element.edit">
                            {{element?.unit}}
                        </div>
                        <div *ngIf="element.edit">
                            <input type="text" formControlName="unit" class="form-control">
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ore">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-ore">Ore</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-ore">
                        {{element?.evento_rel?.durata_lezione}} h</td>
                </ng-container>

                <ng-container matColumnDef="homework">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-student">Homework</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-student">
                        <div *ngIf="!element.edit">
                            {{element?.homework}}
                        </div>
                        <div *ngIf="element.edit">
                            <input type="text" formControlName="homework" class="form-control">
                        </div>
                    </td>
                </ng-container>


                <ng-container formArrayName="studenti">
                    <ng-container *ngFor="let col of studentColumns; let i = index">
                        <ng-container [matColumnDef]="col">
                            <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-student">
                                {{(register | async)[0]?.classe?.classi[i]?.studenti[0]?.name}} {{(register |
                                async)[0]?.classe?.classi[i]?.studenti[0]?.surname}}
                            </th>
                            <td mat-cell *matCellDef="let element; let j = index" class="cursor-pointer col-student"
                                [ngClass]="{
                                    'presenza': element?.registro_anagrafica[i]?.presenza_studente === 'P',
                                    'ritardo': element?.registro_anagrafica[i]?.presenza_studente === 'T',
                                    'assenza': element?.registro_anagrafica[i]?.presenza_studente === 'A'
                                }">

                                <!-- * Visualizzazione valore quando non è in modifica -->
                                <div *ngIf="!element.edit">
                                    {{element.registro_anagrafica[i]?.presenza_studente}}
                                </div>

                                <!-- * Modifica della presenza -->
                                <div *ngIf="element.edit" [formGroupName]="i">
                                    <select class="form-select" formControlName="presenza">
                                        <option *ngFor="let el of obj_presenze" [value]="el.value">{{el.label}}</option>
                                    </select>
                                </div>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>


                <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-student">Notes</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-student">
                        <div *ngIf="!element.edit">
                            {{element?.notes}}
                        </div>
                        <div *ngIf="element.edit">
                            <input type="text" formControlName="notes" class="form-control">
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-action">Modifica</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer col-action bg-white">
                        <button *ngIf="!element.edit" class="btn btn-outline-dark" (click)="setEdit(element)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                <path
                                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                            </svg>
                        </button>
                        <button *ngIf="element.edit" class="btn btn-outline-success" (click)="saveEdit(element)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path
                                    d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                            </svg>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="pageEvent = handlePage($event)" showFirstLastButtons>
        </mat-paginator>
    </form>
</div>