import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ClasseService } from 'src/app/services/class.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { isAdminOrStaff } from 'src/app/utilities/users-utilities';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit {
  adminOrStaff = isAdminOrStaff();
  filter: any;
  data: any;
  displayedColumns: string[] = ['titolo', 'lingua', 'livello', 'azione'];
  currentPage = 0;
  pageSize = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  classes: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  classForm: FormGroup;
  classEditForm: FormGroup;
  pageEvent: PageEvent;
  deleteContent: any;
  boolean = false;
  conversationForm: FormGroup;
  levels = new Array();
  languages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  eta = ['Kids', 'Teen', 'Adult'];
  nome_livello = ['Intero livello', 'Mezzo livello'];
  filter_level: any;
  user_id: string;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tutti = { id: 0, nome: 'Tutti' };

  constructor(
    private fb: FormBuilder,
    private _classeService: ClasseService,
    private _conversationService: ConversationService,
    private _anagraficaService: AnagraficaService,
    private toastr: ToastrService,
    private router: Router,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('it-IT');
    this.classForm = this.fb.group({
      id: [null],
      nome_classe: [null, Validators.required],
      data_inizio: [moment().format('YYYY-MM-DD'), Validators.required],
      data_fine: [moment().format('YYYY-MM-DD'), Validators.required],
      lingua_corso: [null, Validators.required],
      livello_id: [null, Validators.required],
      eta: [null],
      nome_livello: [null],
      stato: [null],
      monte_ore: [null],
      frequenza: [null]
    });
    this.classEditForm = this.fb.group({
      id: [null],
      nome_classe: [null],
      data_inizio: [moment().format('YYYY-MM-DD')],
      data_fine: [moment().format('YYYY-MM-DD')],
      lingua_corso: [null],
      livello_id: [null],
      eta: [null],
      nome_livello: [null],
      stato: [null],
      monte_ore: [null],
      frequenza: [null]
    });

    //FORM CONVERSATION//
    this.conversationForm = this.fb.group({
      id: null,
      titolo: [null, Validators.required],
      lingua: [null, Validators.required],
      livello_id: [null, Validators.required],
      descri: [null]
    })
  }

  ngOnInit(): void {
    this.user_id = localStorage.getItem('user_id');
    this._anagraficaService.getUserId({ user_id: this.user_id }).subscribe(res => {
      this.ruolo.next(res.result?.role);
    });
    $('.tooltipevent').remove();
    this.getData();
    this.getLevel();
    this.getLanguages();
  }

  resetForm() {
    this.conversationForm.reset();
  }

  getData() {
    let params;
    params = { start: this.currentPage * this.pageSize, length: this.pageSize };
    if (this.filter) {
      params = { start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter };
      if (this.filter_level) {
        params = { start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter, filter_level: this.filter_level };
      }
    } else if (this.filter_level) {
      params = { start: this.currentPage * this.pageSize, length: this.pageSize, filter_level: this.filter_level };
    }
    this._conversationService.getConversation(params).subscribe(response => {
      if (response.result) {
        this.classes.next(response.result);
        this.data = new MatTableDataSource(response.result);
        this.records.next(response.total);
      }
    });
  }

  getLevel() {
    this._classeService.getLevel().subscribe(response => {
      if (response.result) {
        this.levels = response.result;
        this.levels.unshift(this.tutti);
        this.conversationForm.patchValue({
          livello_id: response.result[0].id
        })
      }
    });
  }

  showMore() {
    this.boolean = true;
  }

  getLanguages() {
    this._classeService.getLanguage().subscribe(response => {
      if (response.result) {
        this.languages.next(response.result);
        this.conversationForm.patchValue({
          lingua: response.result[0].nome_lingua
        })
      }
    });
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  addConversation() {
    if (this.conversationForm.value.livello_id == 0) {
      this.toastr.error("Inserisci il livello");
      return;
    }
    this._conversationService.addConversation(this.conversationForm.value).subscribe(
      response => {
        if (response.result) {
          //METTERE IL TOST E FARE REFRESH//
          this.toastr.success('Conversion aggiunta con successo');
          this.conversationForm.reset();
          this.getData();
          this.goTo('conversation-details/' + response.result.id)
        }
      }
    )
  }

  setEdit(classe: any) {
    this.conversationForm.patchValue({
      id: classe.id,
      titolo: classe.titolo,
      lingua: classe.lingua,
      livello_id: classe.livello_id,
      descri: classe.descri
    });
  }

  editClass() {
    this._conversationService.editConversation(this.conversationForm.value).subscribe(
      response => {
        if (response.result) {
          //METTERE IL TOST E FARE REFRESH//
          this.toastr.success('Conversation aggiornata con successo');
          this.conversationForm.reset();
          this.getData();
        }
      }
    )
  }

  setClass(classe: any) {
    if (this.deleteContent) {
      this.deleteContent = null;
      this.deleteContent = classe;
    } else {
      this.deleteContent = classe;
    }
    this.ngOnInit();
  }

  deleteClass(id: any) {
    this._conversationService.deleteConversation({ id: id }).subscribe(
      response => {
        if (response) {
          this.toastr.success('Conversation rimossa con successo');
          this.ngOnInit();
        }
      }
    )
  }

  setFilter(filtro) {
    this.filter_level = filtro.target.value;
    this.getData();
  }


  //FUNZIONE CHE SERVE A FARE REDIRECT//
  goTo(string: any) {
    this.router.navigate([string]);
  }

  goToWithId(string: any, id: any) {
    this.router.navigate([string, id]);
  }


}
