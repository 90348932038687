<div class="altezza-page px-0 mx-0" id="wrap2">
    <div class="row px-0 mx-0" style="height: 100%;">
        <div class="col-md-2 px-0 mx-0" style="position: relative">
            <aside class="bg-color">
                <div class="margine-sup padding-logo">
                    <a routerLink="/dashboard">
                        <img alt="Logo" class="h-25px logo" src="/assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>
                <h4 class="section_text"> Classi </h4>
                <div class="menu-item padding-logo">
                    <div class="menu-item ">
                        <div class="row">
                            <div class="col-11">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item" style="border:none">
                                        <h2 class="accordion-header" id="headingOne">
                                            <span class="accordion-button" style="background: #f5f8fa73; height: 40px;"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                Livelli
                                            </span>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <ng-container>
                                                    <div class="col-12 mt-3 mb-2">
                                                        <select class="form-select form-select"
                                                            aria-label="Default select example"
                                                            (change)="setFilter($event)" id="priorita">
                                                            <option hidden selected>Filtra per livelli </option>
                                                            <option *ngFor="let value of (levels)" value="{{value.id}}">
                                                                {{value?.nome}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>

        <div class="col-md-10 margine-sup">
            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0">Classi</h1>
                    </div>
                </div>
            </div>
            <div class="section__body">
                <div class="row my-4 justify-content-between">
                    <div class="col-4 d-flex align-items-center">
                        <input placeholder="Cerca per nome classe" [(ngModel)]="filter" (keyup)="getData()"
                            class="form-control form-control-lg radiusSelect">
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                        <button type="button" class="btn color-butt my-2" data-toggle="modal"
                            data-target="#modalAddClass" *ngIf="adminOrStaff">
                            Aggiungi Classe
                        </button>
                    </div>
                </div>

                <div *ngIf="(records | async) == 0">
                    <div class="no-cont py-4 f-16"><i class="f-16">Nessun risultato riscontrato</i> </div>
                </div>

                <table *ngIf="(records | async) > 0" mat-table class="box_super tb-bord" [dataSource]="classes | async">

                    <ng-container matColumnDef="nome_classe">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-nome"> Nome </th>
                        <td mat-cell *matCellDef="let element" class="cursor-pointer col-nome"
                            (click)="goToWithId('class-details',element.id)" onkeydown="">{{element.nome_classe}}</td>
                    </ng-container>


                    <ng-container matColumnDef="lingua_corso">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-lingua"> Lingua
                        </th>
                        <td mat-cell *matCellDef="let element" class="cursor-pointer col-lingua"
                            (click)="goToWithId('class-details',element.id)" onkeydown="">{{element.lingua_corso}}</td>
                    </ng-container>

                    <ng-container matColumnDef="frequenza">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-eta"> Età </th>
                        <td mat-cell *matCellDef="let element" class="cursor-pointer col-eta"
                            (click)="goToWithId('class-details',element.id)" onkeydown="">{{element?.eta}}</td>
                    </ng-container>

                    <ng-container matColumnDef="livello">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-livello"> Livello
                        </th>
                        <td mat-cell *matCellDef="let element" class="cursor-pointer col-livello"
                            (click)="goToWithId('class-details',element.id)" onkeydown="">{{element?.livello?.nome}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="azione">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga col-azione"> <span
                            *ngIf="adminOrStaff" class="pl-2"> Azione </span></th>
                        <td mat-cell *matCellDef="let element" class="col-azione">
                            <button class="btn btn-outline-dark" (click)="setEdit(element)" data-toggle="modal"
                                data-target="#modalEditClass" *ngIf="adminOrStaff">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                </svg>
                            </button>
                            <button class="btn btn-outline-danger" (click)="setClass(element)" data-toggle="modal"
                                *ngIf="adminOrStaff" data-target="#modalDeleteClass">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                </svg>
                            </button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                    (page)="pageEvent = handlePage($event)" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
</div>




<!-- ! Modal Add Class -->
<div class="modal text-left fade" id="modalAddClass" tabindex="-1" aria-labelledby="modalAddClassLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xxl">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Aggiungi Classe</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="resetClass()"></button>
            </div>
            <div class="modal-body">
                <p><i>N.B. La classe verrà creata inizialmente vuota, aggiungere successivamente un docente e uno
                        studente</i></p>
                <form [formGroup]="classForm">
                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputName">Nome classe</label>
                                <input type="text" class="form-control form-control-lg" formControlName="nome_classe">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputName">Class code</label>
                                <input type="text" class="form-control form-control-lg" formControlName="class_code">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputLevel">Lingua</label>
                                <select class="form-select form-select-lg" formControlName="lingua_corso"
                                    (change)="getAllCertifications($event)">
                                    <option *ngFor="let value of (languages | async)"
                                        value="{{value.nome_lingua}}">
                                        {{value.nome_lingua}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputLevel">Livello</label>
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="livello_id">
                                    <option *ngFor="let value of levels" value="{{value.id}}">{{value.nome}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputLevel">Età</label>
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="eta">
                                    <option *ngFor="let value of eta" value="{{value}}">{{value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputLevel"><b>Certificazione</b></label>
                                <select class="form-select form-select-lg" formControlName="certificazioni">
                                    <option *ngFor="let value of (allCertifications | async)" value={{value.id}}>
                                        {{value.nome}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        
                    </div>

                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputName">Materiale didattico</label>
                                <input type="text" class="form-control form-control-lg" formControlName="materiale_didattico">
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputName">Tipo di avanzamento</label>
                                <select class="form-select form-select-lg" formControlName="tipo_avanzamento">
                                    <option *ngFor="let value of tipo_vanzamento" value={{value.lable}}>
                                        {{value.lable}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2 mx-0">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="inputName">Scopi del corso</label>
                                <textarea class="form-control" style="resize: none" formControlName="scopi_corso" rows="4"></textarea>
                            </div>
                        </div>
                    </div>

                    <p><i>I campi contrassegnati <span style="color: red">*</span> sono obbligatori</i></p>
                    <div class="d-flex justify-content-end">
                        <button type="button" [disabled]="!classForm.valid" class="btn color-butt my-3"
                            data-dismiss="modal" (click)="addClass()">
                            Aggiungi
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


<!-- ! Modal Edit Class -->
<div class="modal text-left fade" id="modalEditClass" tabindex="-1" aria-labelledby="modalEditClassLabel"
    aria-hidden="true">
    <div class="modal-dialog mod-xxl">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Modifica Classe</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="resetEditForm()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="classEditForm">
                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputName">Nome classe</label>
                                <input type="text" class="form-control form-control-lg" formControlName="nome_classe">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputName">Class code</label>
                                <input type="text" class="form-control form-control-lg" formControlName="class_code">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputLevel">Lingua</label>
                                <select class="form-select form-select-lg" formControlName="lingua_corso"
                                    (change)="getAllCertifications($event)">
                                    <option *ngFor="let value of (languages | async)"
                                        value="{{value.nome_lingua}}">
                                        {{value.nome_lingua}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputLevel">Livello</label>
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="livello_id">
                                    <option *ngFor="let value of levels" value="{{value.id}}">{{value.nome}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="ast" for="inputLevel">Età</label>
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="eta">
                                    <option *ngFor="let value of eta" value="{{value}}">{{value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputLevel"><b>Certificazione</b></label>
                                <select class="form-select form-select-lg" formControlName="certificazioni">
                                    <option *ngFor="let value of (allCertifications | async)" value={{value.id}}>
                                        {{value.nome}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2 mx-0">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputName">Materiale didattico</label>
                                <input type="text" class="form-control form-control-lg" formControlName="materiale_didattico">
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputName">Tipo di avanzamento</label>
                                <select class="form-select form-select-lg" formControlName="tipo_avanzamento">
                                    <option *ngFor="let value of tipo_vanzamento" value={{value.lable}}>
                                        {{value.lable}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2 mx-0">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="inputName">Scopi del corso</label>
                                <textarea class="form-control" style="resize: none" formControlName="scopi_corso" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    
                    <p><i>I campi contrassegnati <span style="color: red">*</span> sono obbligatori</i></p>
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn color-butt my-3" (click)="openConfirmModal()"
                            [disabled]="!classEditForm.valid">
                            Aggiorna
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal Delete Member  -->
<div class="modal fade bd-example-modal-sm" id="modalDeleteClass" tabindex="-1" aria-labelledby="deleteClassLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered custom-modal-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina Classe</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente {{deleteContent?.nome_classe}}?</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteClass(deleteContent.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>

<button type="button" class="d-none" #confirmModalEdit data-toggle="modal" data-target="#confirmModal"
    data-backdrop="static" data-keyboard="false"></button>

<!-- ! Modal conferma  -->
<div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true"
    data-backdrop="static" data-keyboard="false" data-append-to="body">
    <div class="modal-dialog modal-dialog-centered custom-modal-width">
        <div class="modal-content">
            <div class="modal-header">


            </div>
            <div class="modal-body fs-5">
                Sei sicuro di voler confermare le modifiche?
            </div>
            <div class="modal-footer no-border-top">
                <button type="button" class="btn btn-secondary font-bold" data-dismiss="modal"
                    (click)="closeConfirmModal()">Annulla</button>
                <button type="button" class="btn color-butt my-3" (click)="editClass()">Conferma</button>
            </div>
        </div>
    </div>
</div>