<div class="mt-2 mb-4">
    <span>La password deve contenere almeno: </span>

    <ng-container [ngTemplateOutlet]="passwordIcon" [ngTemplateOutletContext]="{error: password_errors.lowercase, text: 'Una lettera minuscola'}"></ng-container>
    <ng-container [ngTemplateOutlet]="passwordIcon" [ngTemplateOutletContext]="{error: password_errors.uppercase, text: 'Una lettera maiuscola'}"></ng-container>
    <ng-container [ngTemplateOutlet]="passwordIcon" [ngTemplateOutletContext]="{error: password_errors.number, text: 'Un numero'}"></ng-container>
    <ng-container [ngTemplateOutlet]="passwordIcon" [ngTemplateOutletContext]="{error: password_errors.special, text: 'Un carattere speciale'}"></ng-container>
    <ng-container [ngTemplateOutlet]="passwordIcon" [ngTemplateOutletContext]="{error: password_errors.length, text: '8 caratteri'}"></ng-container>

    <ng-template #passwordIcon let-error='error' let-text='text'>
        <div class="ms-3 d-flex align-items-center">
            <i *ngIf="!error" class="bi bi-check-lg"></i>
            <i *ngIf="error" class="bi bi-x-lg text-danger"></i>
            <span class="ms-2 text-success" [ngClass]="{'text-danger': error}" [innerText]="text"></span>
        </div>
    </ng-template>
</div>