<section class="margine-sup mx-5 pb-5 mb-5">
    <section class="border-bottom">

        <h1>{{(profilo_name|async)?.name}} {{(profilo_name|async)?.surname}}</h1>
    </section>

    <form [formGroup]="imageForm">
        <section class="row mt-4">
            <section class="col-4">
                <h2>Dati personali</h2>
            </section>
            <section class="col-8">

            </section>
        </section>
    </form>

    <form [formGroup]="profiloForm">
        <section class="row mt-3">
            <section class="col-4 d-flex align-items-center">
                <span class="f-17">Nome:</span>
            </section>
            <section class="col-8 form-group">
                <input type="text" class="form-control form-control-lg" formControlName="name">
            </section>
        </section>
        <section class="row mt-3">
            <section class="col-4 d-flex align-items-center">
                <span class="f-17">Cognome:</span>
            </section>
            <section class="col-8 form-group">
                <input type="text" class="form-control form-control-lg" formControlName="surname">
            </section>
        </section>
        <section class="row mt-3">
            <section class="col-4 d-flex align-items-center">
                <span class="f-17">Data di nascita:</span>
            </section>
            <section class="col-8 form-group">
                <input type="date" class="form-control form-control-lg" formControlName="birth_date">
            </section>
        </section>
        <section class="row mt-3">
            <section class="col-4 d-flex align-items-center">
                <span class="f-17">Telefono:</span>
            </section>
            <section class="col-8 form-group">
                <input type="number" class="form-control form-control-lg" formControlName="phone">
            </section>
        </section>
        <section class="d-flex justify-content-end mt-3 pb-3 border-bottom">
            <button class="btn color-butt" (click)="editData()"> Salva modifiche </button>
        </section>
    </form>


    <section class="mt-3">
        <h2>Dati account</h2>
    </section>

    <!-- CAMBIO EMAIL -->
    <section class="border-bottom">
        <form [formGroup]="emailForm">
            <ng-container
                    [ngTemplateOutlet]="fieldTemplate"
                    [ngTemplateOutletContext]="{label: 'Email', type: 'email', placeholder: 'Inserire email attuale', control: 'email', form: emailForm, errors: emailFormErrors}">
            </ng-container>

            <ng-container
                    [ngTemplateOutlet]="fieldTemplate"
                    [ngTemplateOutletContext]="{label: 'Nuova email', type: 'email', placeholder: 'Inserire nuova email', control: 'new_email', form: emailForm, errors: emailFormErrors}">
            </ng-container>
            
            <section class="d-flex justify-content-end my-3">
                <button class="btn color-butt" (click)="editMail()" [disabled]="emailForm.invalid"> Salva modifiche </button>
            </section>
        </form>
    </section>

    <!-- CAMBIO PASSWORD -->
    <section>
        <form [formGroup]="passwordForm">
            <ng-container 
                    [ngTemplateOutlet]="fieldTemplate" 
                    [ngTemplateOutletContext]="{label: 'Password corrente', type: 'password', placeholder: 'Inserire password attuale', control: 'old_password', form: passwordForm, errors: passwordFormErrors}">
            </ng-container>

            <ng-container
                    [ngTemplateOutlet]="fieldTemplate"
                    [ngTemplateOutletContext]="{label: 'Nuova password', type: 'password', placeholder: 'Inserire nuova password', control: 'password', form: passwordForm, errors: passwordFormErrors}">
            </ng-container>

            <ng-container
                    [ngTemplateOutlet]="fieldTemplate"
                    [ngTemplateOutletContext]="{label: 'Conferma nuova password', type: 'password', placeholder: 'Conferma nuova password', control: 'password_confirmation', form: passwordForm, errors: passwordFormErrors}">
            </ng-container>
            
            <section class="row mt-3">
                <section class="col-8 offset-4">
                    <div class="d-flex" [ngClass]="!passwordForm.get('password').value ? 'justify-content-end' : 'justify-content-between'">
                        <password-criterias [ngClass]="{'d-none': !passwordForm.get('password').value}" [control]="passwordForm.get('password')"></password-criterias>

                        <div class="d-flex align-items-end">
                            <button class="btn color-butt" (click)="editPassword()" [disabled]="passwordForm.invalid"> Salva modifiche </button>
                        </div>
                    </div>
                </section>
            </section>
        </form>
    </section>
</section>

<!-- TEMPLATE PER INPUT CON ERRORI -->
<ng-template #fieldTemplate let-label='label' let-type='type' let-placeholder='placeholder' let-control='control' let-form='form' let-errors='errors'>
    <section class="row mt-4" [formGroup]="form">
        <section class="col-4 d-flex align-items-center">
            <span class="f-17"> {{label}}: </span>
        </section>
        
        <section class="col-8 form-group">
            <input [type]="type" class="form-control form-control-lg" [placeholder]="placeholder" [formControlName]="control" [id]="control">
            
            <div *ngIf="errors[control]?.length > 0">
                <div class="text-danger" *ngFor="let error of errors[control]">{{error}}</div>
            </div>
        </section>
    </section>
</ng-template>