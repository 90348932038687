import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClasseService } from 'src/app/services/class.service';

@Component({
  selector: 'app-registro-lezioni',
  templateUrl: './registro-lezioni.component.html',
  styleUrls: ['./registro-lezioni.component.scss']
})
export class RegistroLezioniComponent implements OnInit {
  @Input() class_id: any;

  private destroy$: Subject<void> = new Subject<void>();
  edit: boolean = false;
  displayedColumns: string[] = ['data', 'docente', 'lezione', 'unit', 'ore', 'homework', 'notes', 'action'];
  studentColumns: string[] = [];
  currentPage = 0;
  pageSize = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  pageEvent: PageEvent;
  register: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  registerForm: FormGroup;
  student: any;
  obj_presenze = [
    { label: "Presenza", value: "P" },
    { label: 'Ritardo', value: "T" },
    { label: "Assenza", value: "A" }
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private classService: ClasseService,
    private cdr: ChangeDetectorRef,
  ) {
    this.registerForm = this.fb.group({
      id: [null],
      lezione: [null],
      unit: [null],
      homework: [null],
      notes: [null],
      studenti: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.getRegister();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get studentiArray(): FormArray {
    return this.registerForm.get('studenti') as FormArray;
  }

  getRegister() {
    let params = {skip: this.currentPage * this.pageSize, take: this.pageSize, classe_id: this.class_id }
    this.classService.getRegistro(params).pipe(takeUntil(this.destroy$)).subscribe(res => {
      
      const registerWithEdit = res.result.map(item => ({
        ...item,
        edit: false,
      }));      

      this.register.next(registerWithEdit);      
      this.records.next(res.total);

      if (res.result && res.result.length > 0) {
        const studenti = res.result[0]?.classe?.classi;
        this.student = res.result[0]?.classe?.classi;

        if (studenti && studenti.length > 0) {
          this.studentColumns = studenti.map((_: any, index: number) => `student_${index}`);
          this.displayedColumns = ['data', 'docente', 'lezione', 'unit', 'ore', 'homework', ...this.studentColumns, 'notes', 'action'];
        }
      }
    })
  }
  
  /**
   * Setta l'elemento della riga della tabella, patchando il form del registro.
   * Inizialmente viene effettuato un controllo, nel caso in cui non vi siano presenze in registro_anagrafica,
   * vengono aggiunti gli studenti della classe, altrimenti seleziona i valori già presenti e li patcha.
   * @param element 
   */
  setEdit(element) {
    this.studentiArray.clear();
  
    if (element.registro_anagrafica.length > 0) {
      element.registro_anagrafica.forEach((studente) => {
        this.studentiArray.push(this.fb.group({
          id: [studente.anagrafica_id],
          presenza: [studente.presenza_studente || 'P']
        }));
      });
    } else if (element.classe?.classi) {
      element.classe.classi.forEach((studente) => {
        this.studentiArray.push(this.fb.group({
          id: [studente.studente_id],
          presenza: [studente.anagrafica_id || 'P']
        }));
      });
    }
    

    this.registerForm.patchValue({
      id: element.id,
      lezione: element.lezione,
      homework: element.homework,
      unit: element.unit,
      notes: element.notes
    });

    this.register.next(this.register.value.map(item => ({
      ...item,
      edit: item === element
    })));

    this.cdr.detectChanges();
  }
  

  /**
   * Questa funzione salva gli elementi del registro.
   * Creiamo una variabile studentiPresenze che abbia il valore della select presenze e l'id dello studente, 
   * così da poter mandare al back end l'aggiornamento della riga esatta.
   * @param element
   */
  saveEdit(element) {       
    const studentiPresenze = this.studentiArray.value.map((student: { id: number, presenza: string }) => ({
      id: student.id,
      presenza: student.presenza
    }));

    const saveData = {
      ...this.registerForm.value,
      studenti: studentiPresenze
    };

    this.classService.editRegisterClass(saveData).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.toastr.success('Modifica effettuata');
    });

    element.edit = false;
    this.cdr.detectChanges();
    this.getRegister();
  }


 
  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    return e;
  }
}