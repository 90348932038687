import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormattedResponse } from '../models/formatted-response.model';

const BASE_URL = `${environment.api_URL}`;

@Injectable({
    providedIn: 'root'
})
export class PermissionRoleService {
    constructor(private http: HttpClient) { }

    getRoles(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/roles`);
    }

    // getService(id: number): Observable<FormattedResponse> {
    //     return this.http.get<FormattedResponse>(`${BASE_URL}/services/${id}`);
    // }

    // addMember(data: any): Observable<FormattedResponse> {
    //     return this.http.post<FormattedResponse>(`${BASE_URL}/addMember`, data );
    // }

    // updateUser(id: number, data): Observable<FormattedResponse> {
    //     return this.http.put<FormattedResponse>(`${BASE_URL}/user/${id}`, { data });
    // }

    // deleteUser(id: number): Observable<FormattedResponse> {
    //     return this.http.delete<FormattedResponse>(`${BASE_URL}/user/${id}`);
    // }
}