import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormattedResponse } from '../models/formatted-response.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../interceptors/token-interceptor.service';

const BASE_URL = `${environment.api_URL}`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class PreventiviService {
    constructor(private http: HttpClient) { }

    getPreventivo(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/preventivo`, { params });
    }

    getAllPreventivi(params: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/allPreventivi`, { params, context});
    }

    // getTable(params?: any): Observable<FormattedResponse> {
    //     return this.http.get<FormattedResponse>(`${BASE_URL}/getTable`, { params });
    // }

    addPreventivo(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/insertPreventivo`, data);
    }

    updatePreventivo(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editPreventivo`, data);
    }

    deletePreventivo(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deletePreventivo`, { params });
    }

    createPdf(paramas: any): any {
        return this.http.post<FormattedResponse>(`${BASE_URL}/createPreventiviPDF`, paramas);
    }


    getAllContratti(params: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAllContratti`, { params, context });
    }


    addContratto(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/addContratto`, data);
    }

    updateContratto(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editContratto`, data);
    }

    deleteContratto(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteContratto`, { params });
    }


    createPdfContract(paramas: any): any {
        return this.http.post<FormattedResponse>(`${BASE_URL}/createPdfContract`, paramas);
    }



}