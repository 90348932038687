import {FormGroup, ValidationErrors} from "@angular/forms";

export function confirmPasswordValidator(formGroup: FormGroup): ValidationErrors|null {
    let password = formGroup.get('password').value;
    let password_confirmation = formGroup.get('password_confirmation').value;

    if(password && password_confirmation && password != password_confirmation){
        formGroup.get('password_confirmation').setErrors({invalid: 'Le password non corrispondono'});
        return {invalid: 'Le password non corrispondono'};
    }
    else if(password_confirmation){
        formGroup.get('password_confirmation').setErrors(null);
    }

    return null;
}