<label *ngIf="type != 'checkbox' && label" [for]="control" [ngClass]="{'ast': isRequiredField(control, form)}">
    {{label}}
</label>

<form *ngIf="form" [formGroup]="form">
    <ng-container [ngTemplateOutlet]="inputs"></ng-container>
</form>

<ng-container *ngIf="formcontrol" [ngTemplateOutlet]="inputs"></ng-container>

<!-- INPUTS -->
<ng-template #inputs>
    <div [ngSwitch]="type">
        <ng-container *ngSwitchCase="'input'" [ngTemplateOutlet]="input"></ng-container>
        <ng-container *ngSwitchCase="'date'" [ngTemplateOutlet]="datepicker"></ng-container>
        <ng-container *ngSwitchCase="'select'" [ngTemplateOutlet]="select"></ng-container>
        <ng-container *ngSwitchCase="'autocomplete'" [ngTemplateOutlet]="autocomplete"></ng-container>
        <ng-container *ngSwitchCase="'checkbox'" [ngTemplateOutlet]="checkbox"></ng-container>
    </div>
</ng-template>

<!-- INPUT -->
<ng-template #input>
    <ng-container *ngIf="form" [formGroup]="form">
        <input class="form-control"
            [type]="inputType" 
            [formControlName]="control"
            [id]="control"
            [placeholder]="placeholder ?? ''"
            [ngClass]="{'is-invalid': form.controls[control].invalid && form.controls[control].touched}">
    </ng-container>


    <input *ngIf="formcontrol"
        class="form-control"
        [type]="inputType"
        [formControl]="formcontrol"
        [id]="control"
        [placeholder]="placeholder ?? ''"
        [ngClass]="{'is-invalid': form.controls[control].invalid && form.controls[control].touched}">
</ng-template>

<!-- DATEPICKER -->
<ng-template #datepicker>
    <ng-container *ngIf="form" [formGroup]="form">
        <mat-form-field class="w-100 input-border">
            <input matInput [formControlName]="control" [matDatepicker]="date" readonly>
            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
    </ng-container>
    
    <mat-form-field *ngIf="formcontrol" class="w-100 input-border">
        <input matInput [formControl]="formcontrol" [matDatepicker]="date" readonly>
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
    </mat-form-field>
</ng-template>

<!-- SELECT -->
<ng-template #select>
    <ng-container *ngIf="form" [formGroup]="form">
        <select class="form-select" [formControlName]="control" [id]="control" [ngClass]="{'select-disabled': readonly}">
            <option *ngFor="let item of values" value="{{item.value}}">
                {{item.label}}
            </option>
        </select>
    </ng-container>
    
    <select *ngIf="formcontrol" class="form-select" [formControl]="formcontrol" [id]="control" [ngClass]="{'select-disabled': readonly}">
        <option *ngFor="let item of values" value="{{item.value}}">
            {{item.label}}
        </option>
    </select>
</ng-template>

<!-- AUTOCOMPLETE -->
<ng-template #autocomplete>
    <ng-container *ngIf="form" [formGroup]="form">
        <mat-form-field class="w-100 input-border">
            <input type="text" matInput
                   [formControlName]="control"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <ng-container *ngFor="let item of values">
                    <mat-option [value]="item">{{item.label}}</mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
    </ng-container>
    
    <mat-form-field *ngIf="formcontrol" class="w-100 input-border">
        <input type="text" matInput
               [formControl]="formcontrol"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <ng-container *ngFor="let item of values">
                <mat-option [value]="item">{{item.label}}</mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>

<!-- CHECKBOX -->
<ng-template #checkbox>
    <ng-container *ngIf="form" [formGroup]="form">
        <mat-checkbox color="primary" [formControlName]="control">
            {{label}}
        </mat-checkbox>
    </ng-container>
    
    <mat-checkbox *ngIf="formcontrol" color="primary" [formControl]="formcontrol">
        {{label}}
    </mat-checkbox>
</ng-template>