import * as moment from "moment/moment";

export function isAdminOrStaff(){
    let user_roles: Array<number> = localStorage.getItem('user_roles') ? JSON.parse(localStorage.getItem('user_roles')) : [];
    return user_roles.includes(1) || user_roles.includes(2) || user_roles.includes(3);
}

export function checkUserCategory(birth_date: string) : string{
    const date18YearsAgo = moment().subtract(18, 'years')
    const date10YearsAgo = moment().subtract(10, 'years');
    
    if(moment(birth_date).isSameOrAfter(date10YearsAgo)){
        return 'Kids';
    }
    else if(moment(birth_date).isBetween(date18YearsAgo, date10YearsAgo)){
        return 'Teens';
    }
    else {
        return 'Adults';
    }
}