const sidebar_items = [
    { label: 'Docenti', value: 4, icon: './assets/media/icons/duotune/general/gen019.svg' },
    { label: 'Studenti', value: 5, icon: './assets/media/icons/duotune/art/art002.svg' },
    { label: 'Genitori', value: 6, icon: './assets/media/icons/duotune/art/art002.svg' },
    { label: 'Staff', value: 3, icon: './assets/media/icons/duotune/communication/com006.svg' },
    { label: 'Admin', value: 2, icon: './assets/media/icons/duotune/communication/com006.svg' }
];

const channels = [
    { label: 'Internet', value: '1' },
    { label: 'Facebook', value: '2' },
    { label: 'Instagram', value: '3' },
    { label: 'Insegna', value: '4' },
    { label: 'Passaparola', value: '5' },
    { label: 'Amico', value: '6' },
    { label: 'Ex studenti', value: '7' },
    { label: 'Eventi', value: '8' },
    { label: 'Yesclub', value: '9' },
    { label: 'Email', value: '10' }
];

const subscriptions = [
    { label: 'Iscritto', value: 1 },
    { label: 'Interessato', value: 0 },
];
const categories = [
    { label: 'Adults', value: 'Adults' },
    { label: 'Kids', value: 'Kids' },
    { label: 'Teens', value: 'Teens' }
];

export function getSidebarItems(){
    return sidebar_items;
}

export function getChannels(){
    return channels;
}

export function getSubscriptions(){
    return subscriptions;
}

export function getCategories(){
    return categories;
}