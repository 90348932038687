import { Component, Input } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() form: FormGroup = null;
  @Input() formcontrol: FormControl = null;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() inputType: string = 'text';
  @Input() control: string;
  @Input() label: string;
  @Input() values: Array<any>;
  @Input() readonly: boolean = false;

  /**
   * Funzione di utility per controllare se un campo è required
   * @param field nome del FormControl
   * @param form FormGroup
   */
  isRequiredField(field: string, form: FormGroup): boolean {
    const control = form.get(field);
    if (!control?.validator) return false;

    const validator = control.validator({} as any);
    return validator?.required;
  }

  /**
   * Alla selezione dell'elemento di un autocomplete mostriamo la label
   * @param item
   */
  displayFn(item: any): string {
    return item?.label;
  }
}

