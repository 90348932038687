import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { InlineSVGModule } from 'ng-inline-svg';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseComponent } from './base/base.component';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { MaterialExampleModule } from './material.module';
import { RegistroLezioniComponent } from './pages/registro-lezioni/registro-lezioni.component';
import { AnagraficaComponent } from './pages/anagrafica/anagrafica.component';
import { CalendarConversationComponent } from './pages/calendar-conversation/calendar-conversation.component';
import { ClassiDetailsComponent } from './pages/classi-details/classi-details.component';
import { ClassiComponent } from './pages/classi/classi.component';
import { ContabilitaComponent } from './pages/contabilita/contabilita.component';
import { EntrateComponent } from './pages/contabilita/entrate/entrate.component';
import { UsciteComponent } from './pages/contabilita/uscite/uscite.component';
import { ConversationDetailsComponent } from './pages/conversation-details/conversation-details.component';
import { ConversationComponent } from './pages/conversation/conversation.component';
import { DragCompComponent } from './pages/drag-comp/drag-comp.component';
import { InfoAnagraficaComponent } from './pages/info-anagrafica/info-anagrafica.component';
import { PreventiviComponent } from './pages/preventivi/preventivi.component';
import { ProfiloComponent } from './pages/profilo/profilo.component';
import { RegistroAnagraficaComponent } from './pages/registro-anagrafica/registro-anagrafica.component';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';
import { UtilitiesModule } from "./utilities/utilities.module";

@NgModule({
  declarations: [
    AppComponent,
    AnagraficaComponent,
    AdminPanelComponent,
    ClassiComponent,
    ClassiDetailsComponent,
    InfoAnagraficaComponent,
    ProfiloComponent,
    DragCompComponent,
    RegistroLezioniComponent,
    ConversationComponent,
    ConversationDetailsComponent,
    CalendarConversationComponent,
    ContabilitaComponent,
    EntrateComponent,
    UsciteComponent,
    PreventiviComponent,
    RegistroAnagraficaComponent,
    BaseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatOptionModule,
    MaterialExampleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FullCalendarModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FormsModule,
    ReactiveFormsModule,
    UtilitiesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,

    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
