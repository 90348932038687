import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormattedResponse } from '../models/formatted-response.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../interceptors/token-interceptor.service';

const BASE_URL = `${environment.api_URL}`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class ConversationService {
    constructor(private http: HttpClient) { }


    addConversation(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/addConversation`, data);
    }

    editConversation(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editConversation`, data);
    }

    deleteConversation(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteConversation`, { params });
    }

    getConversation(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getConversation`, { params, context });
    }

    addMemberToConversation(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/addMemberToConversation`, data);
    }

    editMemberToConversation(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editMemberToConversation`, data);
    }

    deletMemberToConversation(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deletMemberToConversation`, { params });
    }

    deleteAllEventByConversation(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteAllEventByConversation`, { params });
    }


    getConversationById(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getConversationByID`, { params });
    }

    getDocentByConversationId(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getDocentByConversationId`, { params });
    }

    getStudentByConversationId(params: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getStudentByConversationId`, { params });
    }

    getAllConversation(params: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAllConversation`, { params });
    }

    getConversationDocente(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/conversationDocente`, { params });
    }


}