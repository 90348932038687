<div class="altezza-page px-0 mx-0" id="wrap2">
    <div class="row px-0 mx-0" style="height: 100%;">
        <div class="col-md-2 px-0 mx-0" style="position: relative">
            <aside class="bg-color">
                <div class="margine-sup padding-logo">
                    <a routerLink="/dashboard">
                        <img alt="Logo" class="h-25px logo" src="/assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>
                <div class="menu-item padding-logo section__tab section_text"></div>
                <div class="menu-item ">
                    <div class="row mx-0">
                        <div class="col-12">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item" style="border:none">
                                    <h2 class="accordion-header" id="headingOne">
                                        <span class="accordion-button" style="background: #f5f8fa73; height: 40px;"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            Filtra per date
                                        </span>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <ng-container>
                                                <div class="col-12 mt-3 mb-2">

                                                    <form [formGroup]="dateForm">
                                                        <div class="form-group">
                                                            <label for="inputSurname"><b>Data inizio</b></label>
                                                            <mat-form-field appearance="outline"
                                                                class="col-12 no_padding">
                                                                <input matInput [matDatepicker]="data_inizio"
                                                                    formControlName="data_inizio"
                                                                    (dateChange)="onStartDateChange()">
                                                                <mat-datepicker-toggle matSuffix
                                                                    [for]="data_inizio"></mat-datepicker-toggle>
                                                                <mat-datepicker #data_inizio></mat-datepicker>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="form-group mt-2">
                                                            <label for="inputSurname"><b>Data fine</b></label>
                                                            <mat-form-field appearance="outline"
                                                                class="col-12 no_padding">
                                                                <input matInput [matDatepicker]="data_fine"
                                                                    formControlName="data_fine" [min]="minEndDate">
                                                                <mat-datepicker-toggle matSuffix
                                                                    [for]="data_fine"></mat-datepicker-toggle>
                                                                <mat-datepicker #data_fine></mat-datepicker>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <button type="button" (click)="resetDate()"
                                                                class="btn btn-danger my-3 px-2 py-1"
                                                                data-dismiss="modal"> Reset </button>
                                                            <button type="button" (click)="setDate()"
                                                                class="btn color-butt my-3 px-2 py-1"
                                                                data-dismiss="modal"> Filtra </button>
                                                        </div>
                                                    </form>

                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <div class="col-md-10 margine-sup">
            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0" *ngIf="flag == 1">Preventivi</h1>
                        <h1 class="mb-0 pb-0" *ngIf="flag == 2">Contratti</h1>
                    </div>
                </div>
            </div>
            <div class="section__body">
                <nav class="mt-5">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                            aria-selected="true" (click)="setFlag(1)">Gestione preventivi</button>
                        <button class="nav-link" id="nav-contract-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-contract" type="button" role="tab" aria-controls="nav-contract"
                            aria-selected="true" (click)="setFlag(2)">Gestione contratti</button>
                    </div>
                </nav>

                <div class="tab-content" id="nav-tabContent">

                    <!-- * TAB PEVENTIVI  -->
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="row my-4">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per titolo o studente" [(ngModel)]="filter"
                                    (keyup)="getAllPreventivi()" class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-6"></div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    data-target="#addModalPreventivo" data-backdrop="static" data-keyboard="false">
                                    Aggiungi preventivo
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records | async) == 0" class="mb-5">
                            <div class="no-cont py-4"><i class="f-16">Nessun risultato riscontrato</i> </div>
                        </div>

                        <div *ngIf="(records | async) > 0">
                            <table mat-table class="box_super tb-bord" [dataSource]="(preventivi|async)">

                                <!-- Titolo -->
                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Titolo
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.titolo}}
                                    </td>
                                </ng-container>

                                <!-- Studente -->
                                <ng-container matColumnDef="studente">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Studente
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element?.studente?.name}} {{element?.studente?.surname}}
                                    </td>
                                </ng-container>

                                <!-- Totale -->
                                <ng-container matColumnDef="totale_costo_corso">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Totale
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <!-- {{(element?.totale_saldare | currency: 'EUR':'symbol')?.replace('.',',')}} -->
                                        {{formatNumber(element?.totale_saldare)}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="data_creazione">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Data
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element?.data_pagamento}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="stato">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Stato
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element?.stato}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="azione">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga large-tab">
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer large-tab">
                                        <button class="btn btn-outline-dark" data-toggle="modal" data-backdrop="static"
                                            data-keyboard="false" (click)="editPreventivo(element)"
                                            data-target="#editModalPreventivo" *ngIf="element.stato!='APPROVATO'">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg>
                                        </button>
                                        <button class="btn btn-outline-danger" data-toggle="modal"
                                            (click)="setDeleteElement(element)" data-target="#modalDeleteClass"
                                            *ngIf="element.stato!='APPROVATO'">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                            </svg>
                                        </button>
                                        <button class="btn btn-outline-primary" (click)="generatePdf(element)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                                                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                                <path
                                                    d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                            </svg>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                            <mat-paginator [length]="(records | async)" [pageSize]="pageSize"
                                [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent = handlePage($event)"
                                showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>

                    <!-- ! TAB CONTRATTI  -->
                    <div class="tab-pane fade show" id="nav-contract" role="tabpanel"
                        aria-labelledby="nav-contract-tab">
                        <div class="row my-4">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per titolo o studente" [(ngModel)]="filterContratti"
                                    (keyup)="getAllContratti()" class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-6"></div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    data-target="#addModalContratto" data-backdrop="static" data-keyboard="false">
                                    Aggiungi contratto
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(recordsContratti | async) == 0" class="mb-5">
                            <div class="no-cont py-4"><i class="f-16">Nessun risultato riscontrato</i> </div>
                        </div>

                        <div *ngIf="(recordsContratti | async) > 0">
                            <table mat-table class="box_super tb-bord" [dataSource]="(contratti|async)">

                                <!-- Titolo -->
                                <ng-container matColumnDef="nome">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Titolo
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.titolo}}
                                    </td>
                                </ng-container>

                                <!-- Studente -->
                                <ng-container matColumnDef="studente">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Studente
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element?.studente?.name}} {{element?.studente?.surname}}
                                    </td>
                                </ng-container>

                                <!-- Costo totale -->
                                <ng-container matColumnDef="totale_costo_corso">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Totale
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <!-- {{(element?.totale_saldare | currency: 'EUR':'symbol')?.replace('.',',')}} -->
                                        {{ formatNumber(element?.totale_saldare) }}
                                    </td>
                                </ng-container>
                                <!--DATA PREVENTIVO-->
                                <ng-container matColumnDef="data_creazione">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Data
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element?.data_pagamento}}
                                    </td>
                                </ng-container>
                                <!--COLONNA AZIONE-->
                                <ng-container matColumnDef="stato">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Stato
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element?.stato}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="azione">
                                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga large-tab">
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="cursor-pointer large-tab">
                                        <button class="btn btn-outline-dark" data-toggle="modal" data-backdrop="static"
                                            data-keyboard="false" data-target="#editModalContratto"
                                            (click)="editPreventivo(element)" *ngIf="element.stato!='APPROVATO'">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg>
                                        </button>
                                        <button class="btn btn-outline-danger" data-toggle="modal"
                                            data-target="#modalDeleteContract" (click)="setDeleteElement(element)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                            </svg>
                                        </button>
                                        <button class="btn btn-outline-primary" (click)="generatePdfContract(element)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                                                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                                <path
                                                    d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                            </svg>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                            <mat-paginator [length]="(recordsContratti | async)" [pageSize]="pageSizeContratti"
                                [pageSizeOptions]="[5, 10, 25, 100]"
                                (page)="pageEventContratti = handlePageContratti($event)" showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! MODALE CREA PREVENTIVO-->
<div class="modal fade" id="addModalPreventivo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="addModalPreventivoLabel">Crea preventivo</h1>
                <button type="button" class="btn-close" (click)="closeModal()" data-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formNuovoPreventivo">
                    <div class="row mb-2">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="select-student"> Seleziona studente </label>
                                <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                                    placeholder="Cerca per nome o cognome" (input)="searchStudents()"
                                    formControlName="search_student">

                                <div class="container" *ngIf="visualizzaStudenti" class="boxautocomplete">
                                    <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                                        *ngFor="let item of (studentis | async)" style="padding:20px;"
                                        (click)="setStudents(item)" onkeyup="">
                                        <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                            {{item.name}} {{item.surname}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1"> Titolo preventivo</label>
                                <input type="text" class="form-control form-control-lg mb-3" formControlName="nome">
                            </div>
                        </div>

                        <div class="col-6">
                            <label for=""><b>Data scadenza</b></label>
                            <mat-form-field appearance="outline" class="col-12 no_padding">
                                <input matInput [min]="today" [matDatepicker]="pickerUpdate"
                                    formControlName="data_scadenza">
                                <mat-datepicker-toggle matSuffix [for]="pickerUpdate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerUpdate></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="consultant">Consultant</label>
                                <input type="text" class="form-control form-control-lg mt-1 consultant-input"
                                    formControlName="consultant">
                            </div>
                        </div>
                    </div>

                    <div class="my-2">
                        <h2 class="modal-title" id="addModalPreventivoLabel">Lingue</h2>
                        <div class="my-3" (keydown.enter)="$event.preventDefault()">
                            <b>Aggiungi Lingua: </b> <button class="btn btn-outline-primary" (click)="addGroup()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                            </button>
                        </div>

                        <!-- GESTIONE FORM ARRAY LINGUE IN MODALE AGGIUNTA PREVENTIVO -->
                        <div formArrayName="array_lingue">
                            <div *ngFor="let row of getControls(); let i = index">
                                <div formGroupName="{{i}}" class="mb-4">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="">Lingua </label>
                                            <div class="md-form">
                                                <select class="form-select form-select-lg"
                                                    aria-label="Default select example" formControlName="lingua">
                                                    <option *ngFor="let value of allLingue"
                                                        value="{{value.nome_lingua}}">
                                                        {{value.nome_lingua}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-5 mr-0 pr-0">
                                            <label for="">Livello </label>
                                            <div class="md-form">
                                                <select class="form-select form-select-lg"
                                                    aria-label="Default select example" formControlName="livello">
                                                    <option *ngFor="let value of livelloLingua" value="{{value.nome}}">
                                                        {{value.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-1 elimina-riga mx-0 px-0">
                                            <div class="box_button_add_tipology">
                                                <button class="btn btn-outline-danger" (click)="deleteAddressGroup(i)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-trash-fill"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for=""> Tipologia corso </label>
                                            <div class="md-form">
                                                <input type="text" class="form-control"
                                                    formControlName="tipologia_corso">
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <label for=""> Totale ore </label>
                                            <div class="md-form">
                                                <input type="number" min="1" class="form-control"
                                                    formControlName="totale_ore">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for=""> Frequenza </label>
                                            <div class="md-form">
                                                <input type="text" class="form-control" formControlName="frequenza">
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <label for=""> Durata (mesi)</label>
                                            <div class="md-form">
                                                <input type="number" class="form-control" formControlName="durata">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--FINE GESTIONE FORM ARRAY LINGUE IN MODALE AGGIUNTA PREVENTIVO -->
                    </div>

                    <div class="form-group">
                        <label for="exampleInputNote">Note</label>
                        <textarea id="form7" class="md-textarea form-control" rows="3"
                            formControlName="note"></textarea>
                    </div>

                    <div class="row mb-2 mt-4">
                        <div class="col-6">
                            <label for="Opzioni">Opzioni</label>
                            <select class="form-select form-select-lg" formControlName="method"
                                (change)="chooseOption($event)">
                                <option *ngFor="let value of typology" value="{{value.id}}">
                                    {{value.label}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- * PRIMO CASO -->
                    <div *ngIf="(filtro_opzione | async) == 1">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="0" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="0"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group my-2">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso" (input)="setTotal()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group my-2">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="form-group my-2">
                            <label for="exampleInputEmail1">Totale da saldare</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_saldare" (change)="generateRate()"
                                readonly>
                        </div>
                    </div>

                    <!-- * Secondo caso -->
                    <div *ngIf="(filtro_opzione | async) == 2">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="setTotal2()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal2()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal2()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="form-group">
                            <label for="exampleInputEmail1">Prima quota (materiale didattico + iscrizione)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row my-2">
                            <!-- *ngIf="fl=='si'" -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Numero di rate</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate2()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <!-- *ngIf="fl=='si'" -->
                                    <label class="ast" for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate2()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (input)="generateRate2()" readonly>
                            </div>
                        </div>
                    </div>

                    <!-- * Terzo caso -->
                    <div *ngIf="(filtro_opzione | async) == 3">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="0" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="0"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="generateRate3()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate3()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate3()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate3()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate3()" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima rata
                                del costo totale) </label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate3()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate3()" readonly>
                            </div>
                        </div>
                    </div>

                    <!-- * Quarto caso -->
                    <div *ngIf="(filtro_opzione | async) == 4">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="0" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="0"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg mb-2" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="generateRate4()">
                        </div>

                        <div class="form-group">
                            <label for="inputAcconto">Acconto</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputAcconto"
                                    placeholder="Inserisci acconto" formControlName="acconto" (input)="generateRate4()">
                            </div>
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate4()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate4()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate4()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate4()" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima rata
                                del costo totale) </label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate4()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate4()" readonly>
                            </div>
                        </div>
                    </div>
                    <!-- Fine quarto caso -->

                    <div class=" mt-4 d-flex justify-content-between">
                        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal"
                            #dismissModalPreventivo>Chiudi</button>
                        <button type="button" class="btn color-butt"
                            (click)="openConfirmModal('preventivo')">Salva</button>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>

<!-- ! MODALE MODIFICA PREVENTIVO-->
<div class="modal fade" id="editModalPreventivo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="editModalPreventivoLabel">Modifica preventivo</h1>
                <button type="button" class="btn-close" (click)="closeModal()" data-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formNuovoPreventivo">
                    <div class="row mb-2">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Titolo preventivo</label>

                                <input type="text" class="form-control form-control-lg mb-3" formControlName="nome">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label> Seleziona studente </label>
                                <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                                    placeholder="Cerca per nome o cognome" (input)="searchStudents()"
                                    formControlName="search_student">

                                <div class="container" *ngIf="visualizzaStudenti" class="boxautocomplete">
                                    <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                                        *ngFor="let item of (studentis | async)" style="padding:20px;"
                                        (click)="setStudents(item)" onkeydown="">
                                        <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                            {{item.name}} {{item.surname}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <label><b>Data scadenza</b></label>
                            <mat-form-field appearance="outline" class="col-12 no_padding">
                                <!-- <mat-label>Data scadenza</mat-label> -->
                                <input matInput [min]="today" [matDatepicker]="picker" formControlName="data_scadenza">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="">Consultant</label>
                                <input type="text" class="form-control form-control-lg mt-1 consultant-input"
                                    formControlName="consultant">
                            </div>
                        </div>
                    </div>

                    <div class="my-3" (keydown.enter)="$event.preventDefault()">
                        <b>Aggiungi Lingua: </b> <button class="btn btn-outline-primary" (click)="addGroup()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                            </svg>
                        </button>
                    </div>

                    <!-- GESTIONE FORM ARRAY LINGUE IN MODALE MODIFICA PREVENTIVO -->
                    <div formArrayName="array_lingue">
                        <h2 class="modal-title my-2" id="addModalPreventivoLabel">Lingue</h2>
                        <div *ngFor="let row of getControls(); let i = index">
                            <div formGroupName="{{i}}" class="mb-4">
                                <div class="row">
                                    <div class="col-6">
                                        <label>Lingua </label>
                                        <div class="md-form">
                                            <select class="form-select form-select-lg"
                                                aria-label="Default select example" formControlName="lingua">
                                                <option *ngFor="let value of allLingue" value="{{value.nome_lingua}}">
                                                    {{value.nome_lingua}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-5 mr-0 pr-0">
                                        <label>Livello </label>
                                        <div class="md-form">
                                            <select class="form-select form-select-lg"
                                                aria-label="Default select example" formControlName="livello">
                                                <option *ngFor="let value of livelloLingua" value="{{value.nome}}">
                                                    {{value.nome}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-1 elimina-riga mx-0 px-0">
                                        <div class="box_button_add_tipology">
                                            <button class="btn btn-outline-danger" (click)="deleteAddressGroup(i)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <label> Tipologia corso </label>
                                        <div class="md-form">
                                            <input type="text" class="form-control" formControlName="tipologia_corso">
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <label> Totale ore </label>
                                        <div class="md-form">
                                            <input type="number" min="1" class="form-control"
                                                formControlName="totale_ore">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <label> Frequenza </label>
                                        <div class="md-form">
                                            <input type="text" class="form-control" formControlName="frequenza">
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <label> Durata (mesi)</label>
                                        <div class="md-form">
                                            <input type="number" class="form-control" formControlName="durata">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- FINE GESTIONE FORM ARRAY LINGUE IN MODALE MODIFICA PREVENTIVO -->

                    <div class="form-group">
                        <label for="exampleInputNote">Note</label>
                        <textarea id="form7" class="md-textarea form-control" rows="3"
                            formControlName="note"></textarea>
                    </div>

                    <div class="row mb-2 mt-4">
                        <div class="col-6">
                            <label for="Opzioni">Opzioni</label>
                            <select class="form-select form-select-lg" formControlName="method"
                                (change)="chooseOption($event)">
                                <option *ngFor="let value of typology" value="{{value.id}}">
                                    {{value.label}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- * PRIMO CASO -->
                    <div *ngIf="(method | async) == 1">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" min="1" type="number"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>


                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso" (input)="setTotal()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="setTotal()">
                        </div> -->

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Totale da saldare</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_saldare" (change)="generateRate()"
                                readonly>
                        </div>
                        <!-- 
                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Numero di rate</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata" readonly>
                                </div>
                            </div>
                        </div> -->


                        <div class="my-2">
                            <label for="">Seleziona stato </label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- *Secondo Caso -->
                    <div *ngIf="(method | async) == 2">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="setTotal2()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal2()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal2()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="setTotal2()">
                        </div> -->

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Prima rata (materiale didattico +
                                iscrizione)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Numero di rate</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate2()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate2()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (input)="generateRate2()" readonly>
                            </div>
                        </div>

                        <div class="my-2">
                            <label for="">Seleziona stato </label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- * Terzo Caso -->
                    <div *ngIf="(method | async) == 3">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (change)="setTotal3()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate3()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate3()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate3()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate3()" readonly>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="generateRate3()">
                        </div> -->

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima
                                rata
                                del costo totale)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate3()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate3()" readonly>
                            </div>
                        </div>

                        <div class="my-2">
                            <label for="">Seleziona stato </label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- * Quarto caso -->
                    <div *ngIf="(method | async) == 4">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg mb-2" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="generateRate4()">
                        </div>

                        <div class="form-group">
                            <label for="inputAcconto">Acconto</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputAcconto"
                                    placeholder="Inserisci acconto" formControlName="acconto" (input)="generateRate4()">
                            </div>
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate4()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate4()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate4()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate4()" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima
                                rata
                                del costo totale)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate4()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate4()" readonly>
                            </div>
                        </div>

                        <div class="my-2">
                            <label for="">Seleziona stato </label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- Fine quarto caso -->

                    <div class="mt-4 d-flex justify-content-between">
                        <button type="button" class="btn btn-secondary" (click)="closeModal()"
                            data-dismiss="modal">Chiudi</button>
                        <button type="button" class="btn color-butt"
                            (click)="openConfirmModalModify('preventivo')">Salva</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- begin: ELIMINA PREVENTIVO-->
<div class="modal fade bd-example-modal-sm" id="modalDeleteClass" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered custom-modal-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina Preventivo</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente {{deleteContent?.titolo}}</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deletePreventivo(deleteContent.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! begin: MODALE CREA CONTRATTO-->
<div class="modal fade" id="addModalContratto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="addModalPreventivoLabel">Crea contratto</h1>
                <button type="button" class="btn-close" (click)="closeModal()" data-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formNuovoPreventivo">
                    <div class="row mb-2">
                        <div class="col-6">
                            <div class="form-group">
                                <label> Seleziona studente </label>
                                <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                                    placeholder="Cerca per nome o cognome" (input)="searchStudents()"
                                    formControlName="search_student">

                                <div class="container" *ngIf="visualizzaStudenti" class="boxautocomplete">
                                    <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                                        *ngFor="let item of (studentis | async)" style="padding:20px;"
                                        (click)="setStudents(item)" onkeydown="">
                                        <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                            {{item.name}} {{item.surname}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="edit-contract-title">Titolo contratto</label>

                                <input id="edit-contract-title" type="text" class="form-control form-control-lg mb-3"
                                    formControlName="nome">
                            </div>
                        </div>
                        <div class="col-6">
                            <label for=""><b>Data scadenza</b></label>
                            <mat-form-field appearance="outline" class="col-12 no_padding">
                                <input matInput [min]="today" [matDatepicker]="pickerAddContratto"
                                    formControlName="data_scadenza">
                                <mat-datepicker-toggle matSuffix [for]="pickerAddContratto"></mat-datepicker-toggle>
                                <mat-datepicker #pickerAddContratto></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>

                    <!-- ? GESTIONE FORM ARRAY-->
                    <div class="my-2">
                        <h2 class="modal-title" id="addModalPreventivoLabel">Lingue</h2>
                        <div class="my-3" (keydown.enter)="$event.preventDefault()">
                            <b>Aggiungi Lingua: </b> <button class="btn btn-outline-primary" (click)="addGroup()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                            </button>
                        </div>

                        <div class="d-flex flex-column mb-4"
                            *ngFor="let element of formNuovoPreventivo.get('array_lingue')['controls'];let i = index"
                            [formGroup]="element">
                            <div class="row">
                                <div class="col-6">
                                    <label for="" class="ast"> Lingua </label>
                                    <div class="md-form">
                                        <select class="form-select form-select-lg" aria-label="Default select example"
                                            formControlName="lingua">
                                            <option *ngFor="let value of allLingue" value="{{value.nome_lingua}}">
                                                {{value.nome_lingua}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-5 mr-0 pr-0">
                                    <label for="" class="ast"> Livello </label>
                                    <div class="md-form">
                                        <select class="form-select form-select-lg" aria-label="Default select example"
                                            formControlName="livello">
                                            <option *ngFor="let value of livelloLingua" value="{{value.nome}}">
                                                {{value.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-1 elimina-riga mx-0 px-0">
                                    <div class="box_button_add_tipology">
                                        <button class="btn btn-outline-danger" (click)="deleteAddressGroup(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label for="" class="ast"> Tipologia corso </label>
                                    <div class="md-form">
                                        <input type="text" class="form-control" formControlName="tipologia_corso">
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="" class="ast"> Totale ore </label>
                                    <div class="md-form">
                                        <input type="number" min="1" class="form-control" formControlName="totale_ore">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label for="" class="ast"> Frequenza </label>
                                    <div class="md-form">
                                        <input type="text" class="form-control" formControlName="frequenza">
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="" class="ast"> Durata (mesi)</label>
                                    <div class="md-form">
                                        <input type="number" class="form-control" formControlName="durata">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="exampleInputNote">Note</label>
                        <textarea id="form7" class="md-textarea form-control" rows="3"
                            formControlName="note"></textarea>
                    </div>

                    <div class="row mb-2 mt-4">
                        <div class="col-6">
                            <label for="Opzioni">Opzioni</label>
                            <select class="form-select form-select-lg" formControlName="method"
                                (change)="chooseOption($event)">
                                <option *ngFor="let value of typology" value="{{value.id}}">
                                    {{value.label}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- * PRIMO CASO -->

                    <div *ngIf="(filtro_opzione | async) == 1">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso" (input)="setTotal()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="setTotal()">
                        </div> -->

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Totale da saldare</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_saldare" (change)="generateRate()"
                                readonly>
                        </div>


                        <!-- *ngIf="fl=='si'"
                        <div class="row my-2">

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Numero di rate</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                    
                                    <label class="ast" for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"  readonly>
                                </div>
                            </div>
                        </div>-->
                    </div>

                    <!-- * Secondo caso -->
                    <div *ngIf="(filtro_opzione | async) == 2">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (change)="setTotal2()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal2()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal2()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="setTotal2()">
                        </div> -->

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Prima rata (materiale didattico +
                                iscrizione)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row my-2">
                            <!-- *ngIf="fl=='si'" -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Numero di rate</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate2()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <!-- *ngIf="fl=='si'" -->
                                    <label class="ast" for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate2()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (input)="generateRate2()" readonly>
                            </div>
                        </div>
                    </div>

                    <!-- * Terzo caso -->
                    <div *ngIf="(filtro_opzione | async) == 3">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (change)="generateRate3()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate3()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate3()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (change)="generateRate3()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate3()" readonly>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="generateRate3()">
                        </div> -->

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima rata
                                del costo totale)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate3()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate3()" readonly>
                            </div>
                        </div>
                    </div>

                    <!-- Quarto caso contratto -->
                    <div *ngIf="(filtro_opzione | async) == 4">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="generateRate4()">
                        </div>

                        <div class="form-group">
                            <label for="input">Acconto</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputAcconto"
                                    placeholder="Inserisci acconto" formControlName="acconto"
                                    (input)="maxWords($event); generateRate4()">
                            </div>
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate4()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate4()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate4()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate4()" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima rata
                                del costo totale)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate4()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate4()" readonly>
                            </div>
                        </div>
                    </div>
                    <!-- Fine quarto caso -->


                    <div class="form-group my-5">
                        <h2>Note aggiuntive</h2>

                        <div class="form-group mt-3">
                            <div *ngFor="let flag of note_flags" class="form-check mb-2">
                                <input id="check-{{flag.id}}" class="form-check-input" type="checkbox"
                                    [formControlName]="flag.name">
                                <label for="check-{{flag.id}}">{{ flag.label }}</label>
                            </div>
                        </div>

                        <div class="form-group mt-4">
                            <label for="other-ending-notes">Altre note</label>
                            <textarea id="other-ending-notes" type="textarea" class="form-control"
                                formControlName="altre_note" rows="3"></textarea>
                        </div>
                    </div>

                    <div class="mt-4 d-flex justify-content-between">
                        <button type="button" class="btn btn-secondary" (click)="closeModal()"
                            data-dismiss="modal">Chiudi</button>
                        <button type="button" class="btn color-butt"
                            (click)="openConfirmModal('contratto')">Salva</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- begin: MODALE MODIFICA CONTRATTO-->
<div class="modal fade" id="editModalContratto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="editModalPreventivoLabel">Modifica contratto</h1>
                <button type="button" class="btn-close" (click)="closeModal()" data-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formNuovoPreventivo">
                    <div class="row mb-2">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="new-contract-title"> Titolo contratto</label>

                                <input id="new-contract-title" type="text" class="form-control form-control-lg mb-3"
                                    formControlName="nome">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="student"> Seleziona studente </label>
                                <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                                    placeholder="Cerca per nome o cognome" (input)="searchStudents()"
                                    formControlName="search_student">

                                <div class="container" *ngIf="visualizzaStudenti" class="boxautocomplete">
                                    <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                                        *ngFor="let item of (studentis | async)" style="padding:20px;"
                                        (click)="setStudents(item)" onKeyPress="">
                                        <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                            {{item.name}} {{item.surname}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <label for="end-date"><b>Data scadenza</b></label>
                            <mat-form-field appearance="outline" class="col-12 no_padding">
                                <input matInput [min]="today" [matDatepicker]="pickerEditContratto"
                                    formControlName="data_scadenza">
                                <mat-datepicker-toggle matSuffix [for]="pickerEditContratto"></mat-datepicker-toggle>
                                <mat-datepicker #pickerEditContratto></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>

                    <!--? GESTIONE FORM ARRAY-->
                    <div class="my-2">
                        <h2 class="modal-title my-2" id="addModalPreventivoLabel">Lingue</h2>
                        <div class="my-3" (keydown.enter)="$event.preventDefault()">
                            <b>Aggiungi Lingua: </b> <button class="btn btn-outline-primary" (click)="addGroup()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                            </button>
                        </div>

                        <div class="d-flex flex-column"
                            *ngFor="let element of formNuovoPreventivo.get('array_lingue')['controls'];let i = index"
                            [formGroup]="element">
                            <div class="row">
                                <div class="col-6">
                                    <label for="lang" class="ast">Lingua </label>
                                    <div class="md-form">
                                        <select class="form-select form-select-lg" aria-label="Default select example"
                                            formControlName="lingua">
                                            <option *ngFor="let value of allLingue" value="{{value.nome_lingua}}">
                                                {{value.nome_lingua}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-5 mr-0 pr-0">
                                    <label for="level" class="ast">Livello </label>
                                    <div class="md-form">
                                        <select class="form-select form-select-lg" aria-label="Default select example"
                                            formControlName="livello">
                                            <option *ngFor="let value of livelloLingua" value="{{value.nome}}">
                                                {{value.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-1 elimina-riga mx-0 px-0">
                                    <div class="box_button_add_tipology">
                                        <button class="btn btn-outline-danger" (click)="deleteAddressGroup(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <label for="" class="ast"> Tipologia corso </label>
                                    <div class="md-form">
                                        <input type="text" class="form-control" formControlName="tipologia_corso">
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="" class="ast"> Totale ore </label>
                                    <div class="md-form">
                                        <input type="number" min="1" class="form-control" formControlName="totale_ore">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label for="" class="ast"> Frequenza </label>
                                    <div class="md-form">
                                        <input type="text" class="form-control" formControlName="frequenza">
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="" class="ast"> Durata (mesi)</label>
                                    <div class="md-form">
                                        <input type="number" class="form-control" formControlName="durata">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="exampleInputNote">Note</label>
                        <textarea id="form7" class="md-textarea form-control" rows="3"
                            formControlName="note"></textarea>
                    </div>

                    <div class="row mb-2 mt-4">
                        <div class="col-6">
                            <label for="Opzioni">Opzioni</label>
                            <select class="form-select form-select-lg" formControlName="method"
                                (change)="chooseOption($event)">
                                <option *ngFor="let value of typology" value="{{value.id}}">
                                    {{value.label}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- * Primo Caso -->
                    <div *ngIf="(method | async) == 1">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>


                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso" (input)="setTotal()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="setTotal()">
                        </div> -->

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Totale da saldare</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_saldare" (change)="generateRate()"
                                readonly>
                        </div>

                        <!-- <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Numero di rate</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata" readonly>
                                </div>
                            </div>
                        </div> -->

                        <div class="my-2">
                            <label for="">Seleziona stato </label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato" (change)="onStatoChange($event)">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- *Secondo Caso -->
                    <div *ngIf="(method | async) == 2">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="setTotal2()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); setTotal2()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="setTotal2()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="setTotal2()">
                        </div> -->

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Prima rata (materiale didattico +
                                iscrizione)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Numero di rate</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate2()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate2()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (input)="generateRate2()" readonly>
                            </div>
                        </div>

                        <div class="my-2">
                            <label for="">Seleziona stato </label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato" (change)="onStatoChange($event)">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div *ngIf="checkTrancheStatus()" class="col-6">
                            <label for="payment-start-date"><b>Data di inizio pagamento</b></label>
                            <mat-form-field appearance="outline" class="col-12 no_padding">
                                <input matInput [min]="today" [matDatepicker]="pickerUpdate"
                                    formControlName="data_inizio_pagamento">
                                <mat-datepicker-toggle matSuffix [for]="pickerUpdate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerUpdate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- * Terzo Caso -->
                    <div *ngIf="(method | async) == 3">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (change)="generateRate3()">
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate3()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate3()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate3()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate3()" readonly>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group my-2">
                            <label for="acconto">Acconto versato</label>
                            <input type="number" min="0" class="form-control form-control-lg" id="acconto"
                                placeholder="Inserisci acconto" formControlName="acconto" (input)="generateRate3()">
                        </div> -->

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima rata
                                del costo totale)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate3()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate3()" readonly>
                            </div>
                        </div>

                        <div class="my-2">
                            <label for="">Seleziona stato</label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato" (change)="onStatoChange($event)">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div *ngIf="checkTrancheStatus()" class="col-6">
                            <label for="payment-start-date"><b>Data di inizio pagamento</b></label>
                            <mat-form-field appearance="outline" class="col-12 no_padding">
                                <input matInput [min]="today" [matDatepicker]="pickerUpdate"
                                    formControlName="data_inizio_pagamento">
                                <mat-datepicker-toggle matSuffix [for]="pickerUpdate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerUpdate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Quarto caso contratto -->
                    <div *ngIf="(method | async) == 4">
                        <div class="row my-2">
                            <h2 class="modal-title my-4" id="addModalPreventivoLabel">Informazioni pagamento</h2>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_materiale_didattico">Sconto materiale didattico:</label>
                                    <select id="sconto_materiale_didattico" class="form-select form-select-lg"
                                        formControlName="sconto_materiale_didattico"
                                        (change)="updateMaterialeDidatticoCost($event)">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_materiale_didattico"
                                            [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="sconto_tassa_iscrizione">Sconto tassa iscrizione:</label>
                                    <select id="sconto_tassa_iscrizione" class="form-select form-select-lg"
                                        formControlName="sconto_tassa_iscrizione"
                                        (change)="updateTassaIscrizioneCost()">
                                        <option [ngValue]="null">Nessuno sconto</option>
                                        <option *ngFor="let sconto of sconto_tassa_iscrizione" [ngValue]="sconto.value">
                                            {{ sconto.lable }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="materiale_didattico_qty">Quantità materiale didattico:</label>
                                    <input id="materiale_didattico_qty" class="form-control form-control-lg"
                                        type="number" min="1" formControlName="costo_materiale_didattico"
                                        [readonly]="formNuovoPreventivo.get('sconto_materiale_didattico').value !== null"
                                        (input)="updateMaterialeDidatticoCost($event)" />
                                </div>
                                <p class="mb-0">Totale materiale didattico: {{ totaleMaterialeDidattico |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>


                            <div class="col-6">
                                <div class="form-group">
                                    <label for="tassa_iscrizione_qty">Quantità tassa iscrizione:</label>
                                    <input id="tassa_iscrizione_qty" type="number" min="1"
                                        class="form-control form-control-lg" formControlName="tassa_iscrizione"
                                        [readonly]="formNuovoPreventivo.get('sconto_tassa_iscrizione').value !== null"
                                        (input)="updateTassaIscrizioneCost()" />
                                </div>
                                <p class="mb-0">Totale tassa iscrizione: {{ totaleTassaIscrizione |
                                    currency:'EUR':'symbol':'1.2-2' }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="ast" for="exampleInputEmail1">Costo totale corso</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="totale_costo_corso"
                                (input)="generateRate4()">
                        </div>

                        <div class="form-group">
                            <label for="inputAcconto">Acconto</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputAcconto"
                                    placeholder="Inserisci acconto" formControlName="acconto" (input)="generateRate4()">
                            </div>
                        </div>

                        <mat-slide-toggle color="primary" formControlName="check_sconto_mensile">
                            {{ isDiscountMonthly ? 'Sconto mensile' : 'Sconto libero' }}
                        </mat-slide-toggle>

                        <div *ngIf="!isDiscountMonthly; else discountMonthly" class="form-group">
                            <label for="exampleInputEmail1">Sconto %</label>
                            <div class="input-group mb-2">
                                <input type="number" class="form-control form-control-lg" id="inputSconto"
                                    placeholder="Inserisci sconto in %" formControlName="sconto"
                                    (input)="maxWords($event); generateRate4()">
                            </div>
                        </div>

                        <ng-template #discountMonthly>
                            <div class="row my-2">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="titolo_sconto">Titolo sconto:</label>
                                        <input type="text" class="form-control form-control-lg"
                                            formControlName="titolo_sconto_mensile" />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="cifra_sconto_mensile">Cifra da scontare:</label>
                                        <input type="number" min="0" class="form-control form-control-lg"
                                            formControlName="cifra_sconto_mensile" (input)="generateRate4()" />
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <div class="row my-2">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="ast" for="exampleInputEmail1">Rate totali</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="numero_rate"
                                        (input)="generateRate4()">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Importo rata</label>
                                    <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                        class="form-control form-control-lg" id="exampleInputEmail1"
                                        placeholder="Inserisci costo" formControlName="importo_rata"
                                        (change)="generateRate4()" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-2">
                            <label for="exampleInputEmail1">Prima rata (materiale didattico + iscrizione + prima rata
                                del costo totale)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                class="form-control form-control-lg" id="exampleInputEmail1"
                                placeholder="Inserisci costo" formControlName="prima_rata" readonly>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Rate rimanenti</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="rate_rimanenti"
                                    (change)="generateRate4()">
                            </div>
                            <div class="col-6 form-group mb-2">
                                <label for="exampleInputEmail1">Totale da saldare</label>
                                <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                    class="form-control form-control-lg" id="exampleInputEmail1"
                                    placeholder="Inserisci costo" formControlName="totale_saldare"
                                    (change)="generateRate4()" readonly>
                            </div>
                        </div>

                        <div class="my-2">
                            <label for="">Seleziona stato</label>
                            <div class="md-form">
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="stato" (change)="onStatoChange($event)">
                                    <option *ngFor="let value of (stati)" value="{{value?.id}}">{{value?.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div *ngIf="checkTrancheStatus()" class="col-6">
                            <label for="payment-start-date"><b>Data di inizio pagamento</b></label>
                            <mat-form-field appearance="outline" class="col-12 no_padding">
                                <input matInput [min]="today" [matDatepicker]="pickerUpdate"
                                    formControlName="data_inizio_pagamento">
                                <mat-datepicker-toggle matSuffix [for]="pickerUpdate"></mat-datepicker-toggle>
                                <mat-datepicker #pickerUpdate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group my-5">
                        <h2>Note aggiuntive</h2>

                        <div class="form-group mt-3">
                            <div *ngFor="let flag of note_flags" class="form-check mb-2">
                                <input id="check-{{flag.id}}" class="form-check-input" type="checkbox"
                                    [formControlName]="flag.name">
                                <label for="check-{{flag.id}}">{{ flag.label }}</label>
                            </div>
                        </div>

                        <div class="form-group mt-4">
                            <label for="other-ending-notes">Altre note</label>
                            <textarea id="other-ending-notes" type="textarea" class="form-control"
                                formControlName="altre_note" rows="3"></textarea>
                        </div>
                    </div>

                    <div class="mt-4 d-flex justify-content-between">
                        <button type="button" class="btn btn-secondary" (click)="closeModal()"
                            data-dismiss="modal">Chiudi</button>
                        <button type="button" class="btn color-butt"
                            (click)="openConfirmModalModify('contratto')">Salva</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


<!-- begin: MODALE ELIMINA CONTRATTO-->
<div class="modal fade bd-example-modal-sm" id="modalDeleteContract" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered custom-modal-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina Contratto</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente {{deleteContent?.titolo}}</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteContratto(deleteContent.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>

<button type="button" class="d-none" #confirmModalButton data-toggle="modal" data-target="#confirmModal"
    data-backdrop="static" data-keyboard="false"></button>
<!-- begin : MODALE CONFERMA CREAZIONE  -->
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false" data-append-to="body">
    <div class="modal-dialog modal-dialog-centered custom-modal-width" role="document">
        <div class="modal-content">
            <div class="modal-header">


            </div>
            <div class="modal-body fs-5">
                Sei sicuro salvare il {{ typeSaving }}?
            </div>
            <div class="modal-footer no-border-top">
                <button type="button" class="btn btn-secondary font-bold" data-dismiss="modal">Annulla</button>
                <button type="button" class="btn color-butt my-3"
                    (click)="typeSaving == 'preventivo' ? addPreventivo() : addContratto(); closeConfirmModal()">Conferma</button>
            </div>
        </div>
    </div>
</div>

<button class="d-none" #confirmModalModifyButton data-toggle="modal" data-target="#confirmModalModify"
    data-backdrop="static" data-keyboard="false" type="button"></button>
<!-- begin: MODALE CONFERMA MODIFICA  -->
<div class="modal fade" id="confirmModalModify" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false" data-append-to="body">
    <div class="modal-dialog modal-dialog-centered custom-modal-width" role="document">
        <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body fs-5">
                Sei sicuro di voler modificare il {{ typeSaving }}?
            </div>
            <div class="modal-footer no-border-top">
                <button type="button" class="btn btn-secondary font-bold" data-dismiss="modal">Annulla</button>
                <button type="button" class="btn color-butt my-3"
                    (click)="typeSaving == 'preventivo' ? storeEditPreventivo() : storeEditContratto(); closeConfirmModalModify()">Conferma</button>
            </div>
        </div>
    </div>
</div>