import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export type UserType = UserModel | undefined;


@Injectable({
  providedIn: 'root',

})
export class LoginService {
  user: any;
  token: any;

  setUser(user: any) {
    this.user = user;
  }
  getUser() {
    return this.user;
  }
  deleteUser() {
    this.user = null;
  }

  setToken(token: any) {
    localStorage.setItem('user_token', token);
    this.token = token;
  }

  removeToken() {
    this.token = null;
  }

  constructor(
    private http: HttpClient
  ) {

  }

  login(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api_URL}/auth/login`, data);

  }

  logout(): Observable<any> {
    return this.http.get<any>(`${environment.api_URL}/auth/logout`);
  }

  register(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api_URL}/auth/register`, data);
  }
  
  resetPassword(user_id, params): Observable<any> {
    return this.http.patch<any>(`${environment.api_URL}/auth/user/${user_id}/reset-password`, params);
  }
}