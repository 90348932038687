import {Component, Input, SimpleChanges} from '@angular/core';
import { FormGroup } from "@angular/forms";
import { checkIncludedFields } from "../form-utilities";

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
  @Input() userForm: FormGroup;
  @Input() roles = [];
  @Input() channels = [];
  @Input() categories = [];
  @Input() subscriptions = [];
  @Input() detail: boolean = false;

  constructor() { }
  
  ngOnChanges(changes: SimpleChanges): void {
    //solo nel caso di modifica è possibile cambiare i ruoli di studenti e genitori
    if(this.detail && changes?.roles?.currentValue.length > 0 && checkIncludedFields(this.userForm, 'role', [5, 6])){
      this.roles = this.roles.filter(item => item.value == 5 || item.value == 6);
    }
  }
  
  public readonly checkIncludedFields = checkIncludedFields;
}
