import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ContabilitaService } from 'src/app/services/contabilita.service';

@Component({
  selector: 'app-contabilita',
  templateUrl: './contabilita.component.html',
  styleUrls: ['./contabilita.component.scss']
})
export class ContabilitaComponent implements OnInit {
  pagamenti = [
    { label: 'Contanti', value: 'Contanti' },
    { label: 'Bonifico', value: 'Bonifico' },
    { label: 'Carte di credito', value: 'Carte di credito' }
  ];
  currentPage = 0;
  currentPage2 = 0;
  pageSize = 10;
  pageSize2 = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  records2: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  displayedColumns: string[] = ['intestazione', 'nome', 'data', 'entrata', 'stato', 'azione'];
  displayedColumns2: string[] = ['intestazione', 'nome', 'data', 'uscita', 'azione'];
  data_input: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data_output: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  inputAddForm: FormGroup;
  inputEditForm: FormGroup;
  outputAddForm: FormGroup;
  outputEditForm: FormGroup;
  dateForm: FormGroup;
  filter: any;
  filter2: any;
  entrate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  uscite: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data;
  pageEvent: PageEvent;
  pageEvent2: PageEvent;
  flag0 = null;
  flag1 = null;
  deleteContent: any;
  deleteContent2: any;
  studente_settato;
  inOutFlag = [{ label: 'Entrata', valore: 0 }, { label: 'Uscita', valore: 1 }];
  elencoAnagrafica: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  flagFilter = null;
  nessuno = { id: null, name: 'Nessuno', surname: '' };
  stati = [
    { id: 'Saldato', label: 'Saldato' },
    { id: 'Non saldato', label: 'Non saldato' },
  ];
  visualizzaTutti = false;
  tutti: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  saldati: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  prova;
  data_inizio;
  data_fine;
  minEndDate: any;

  constructor(
    private fb: FormBuilder,
    private _contabilitaService: ContabilitaService,
    private _anagraficaService: AnagraficaService,
    private toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('it-IT');
    this.inputAddForm = this.fb.group({
      id: [null],
      anagrafica_id: [null],
      intestazione: [null, Validators.required],
      data: [moment().format('YYYY-MM-DD'), Validators.required],
      ricevuta: [null],
      oggetto: [null],
      entrata: [null, Validators.required],
      stato: [null, Validators.required],
      i_p: [null],
      iva_entrata: null,
      flag: [null, Validators.required],
      search_member: [null],
    });
    this.inputEditForm = this.fb.group({
      id: [null],
      anagrafica_id: [null],
      intestazione: [null, Validators.required],
      data: [moment().format('YYYY-MM-DD'), Validators.required],
      ricevuta: [null],
      oggetto: [null],
      entrata: [null, Validators.required],
      stato: [null, Validators.required],
      i_p: [null],
      iva_entrata: null,
      flag: [null, Validators.required]
    });
    this.outputAddForm = this.fb.group({
      id: [null],
      anagrafica_id: [null],
      intestazione: [null, Validators.required],
      data: [moment().format('YYYY-MM-DD'), Validators.required],
      ricevuta: [null, Validators.required],
      oggetto: [null, Validators.required],
      uscita: [null, Validators.required],
      i_p: [null],
      iva_uscita: null,
    });
    this.outputEditForm = this.fb.group({
      id: [null],
      anagrafica_id: [null, Validators.required],
      intestazione: [null],
      data: [moment().format('YYYY-MM-DD'), Validators.required],
      ricevuta: [null],
      oggetto: [null],
      uscita: [null],
      i_p: [null],
      iva_uscita: null,
    });
    this.dateForm = this.fb.group({
      data_inizio: [null],
      data_fine: [null]
    });
    this.dateForm.get('data_inizio').valueChanges.subscribe(value => {
      this.minEndDate = value;
    });
  }

  ngOnInit(): void {
    $('.tooltipevent').remove();
    this.delay();
    this.getMembers();
    //FORMATTO LE DUE DATE//
    var year = new Date().getFullYear();
    var first_date = new Date(year, 0, 1);
    var last_date = new Date(year, 11, 31);
    var first_date2 = (this.formatDate(first_date));
    var last_date2 = (this.formatDate(last_date));
    this.dateForm.patchValue({ data_inizio: first_date2 });
    this.dateForm.patchValue({ data_fine: last_date2 });
    this.getData();
  }

  onStartDateChange(): void {
    const startDate = this.dateForm.get('data_inizio').value;
    if (startDate) {
      this.minEndDate = startDate;
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  setFlagnull() {
    this.flagFilter = null;
    this.inputEditForm.value.flag = 0
    this.inputAddForm.value.flag = 0
    this.getData();
  }

  setFlag0() {
    this.flagFilter = 0
    this.inputEditForm.value.flag = 0
    this.inputAddForm.value.flag = 0
    this.getData();
  }

  setFlag1() {
    this.flagFilter = 1
    this.inputAddForm.value.flag = 1
    this.inputEditForm.value.flag = 1
    this.getData();
  }

  getMembers() {
    this._anagraficaService.getMembers().subscribe(response => {
      if (response.result) {
        response.result.unshift(this.nessuno);
        this.elencoAnagrafica.next(response.result);
      }
    });
  }

  searchAll() {
    let params = { like_search: this.inputAddForm.value.search_member };
    this._anagraficaService.searchAll(params).subscribe(response => {
      if (response.result) {
        this.visualizzaTutti = true;
      }
      this.tutti.next(response.result);
    })
  }

  setMember(stud) {
    this.inputAddForm.patchValue({
      anagrafica_id: stud.id,
      search_member: stud.name + " " + stud.surname
    })
    this.visualizzaTutti = false;
  }

  setDate() {
    this.dateForm.patchValue({
      data_inizio: this.getFormattedDate(this.dateForm.value.data_inizio, 'YYYY-MM-DD'),
      data_fine: this.getFormattedDate(this.dateForm.value.data_fine, 'YYYY-MM-DD')
    });
    if (this.dateForm.value.data_inizio > this.dateForm.value.data_fine) {
      this.toastr.error('Data fine antecedente alla data di inizio');
    } else {
      this.getData();
    }
  }

  resetDate() {
    var year = new Date().getFullYear();
    var first_date = new Date(year, 0, 1);
    var last_date = new Date(year, 11, 31);
    var first_date2 = (this.formatDate(first_date));
    var last_date2 = (this.formatDate(last_date));
    this.dateForm.patchValue({ data_inizio: first_date2 });
    this.dateForm.patchValue({ data_fine: last_date2 });
    this.minEndDate = null;
    this.getData()
  }

  getData() {
    let params = this.filter ? { flag: this.flagFilter, start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter, data_inizio: this.dateForm.value.data_inizio, data_fine: this.dateForm.value.data_fine } : { flag: this.flagFilter, start: this.currentPage * this.pageSize, length: this.pageSize, data_inizio: this.dateForm.value.data_inizio, data_fine: this.dateForm.value.data_fine };
    this._contabilitaService.getTable(params).subscribe(response => {
      if (response.result) {
        this.entrate.next(response.result);
        this.data = new MatTableDataSource(response.result);
        this.records.next(response.total);
      }
    });
  }

  getData2() {
    let params = this.filter2 ? { start: this.currentPage2 * this.pageSize2, length: this.pageSize2, like_search: this.filter2 } : { start: this.currentPage2 * this.pageSize2, length: this.pageSize2 };
    this._contabilitaService.getOutTable(params).subscribe(response => {
      if (response.result) {
        this.uscite.next(response.result);
        this.records2.next(response.total);
      }
    });
  }

  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
    // date: this.getFormattedDate(special.date, 'YYYY-MM-DD'),
  }

  addEntrata() {
    this.inputAddForm.patchValue({
      data: this.getFormattedDate(this.inputAddForm.value.data, 'YYYY-MM-DD')
    });
    this._contabilitaService.addInput(this.inputAddForm.value).subscribe(
      response => {
        if (response.status == true) {
          this.inputAddForm.reset();
          this.toastr.success('Aggiunta con successo');
          this.getData();
        }
        else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  setEditEntrata(entrata: any) {
    if (this.flagFilter == 0) {
      this.inputEditForm.patchValue({ flag: this.flagFilter });
    } else if (this.flagFilter == 1) {
      this.inputEditForm.patchValue({ flag: this.flagFilter });
    } else {
      this.inputEditForm.value.flag = null
    }
    this.inputEditForm.patchValue({
      id: entrata.id,
      anagrafica_id: entrata.anagrafica_id,
      intestazione: entrata.intestazione,
      data: this.getFormattedDate(entrata.data, 'YYYY-MM-DD'),
      ricevuta: entrata.ricevuta,
      oggetto: entrata.oggetto,
      entrata: entrata.entrata,
      stato: entrata.stato,
      i_p: entrata.i_p,
      iva_entrata: entrata.iva_entrata,
      frequenza: entrata.frequenza,
      flag: entrata.flag
    });
  }

  clickModale() {
    if (this.flagFilter == 0) {
      this.inputAddForm.value.flag = 0
    } else {
      this.inputAddForm.value.flag = 1
    }
  }

  openModal() {
    if (this.flagFilter == 0) {
      this.inputAddForm.patchValue({ flag: this.flagFilter });
    } else if (this.flagFilter == 1) {
      this.inputAddForm.patchValue({ flag: this.flagFilter });
    } else {
      this.inputAddForm.value.flag = null
    }
  }

  openEditModal() {
    if (this.flagFilter == 0) {
      this.inputEditForm.patchValue({ flag: this.flagFilter });
    } else if (this.flagFilter == 1) {
      this.inputEditForm.patchValue({ flag: this.flagFilter });
    } else {
      this.inputEditForm.value.flag = null
    }
  }

  resetModal() {
    this.inputAddForm.reset();
  }

  editEntrata() {
    this.inputEditForm.patchValue({
      data: this.getFormattedDate(this.inputEditForm.value.data, 'YYYY-MM-DD')
    });
    this._contabilitaService.updateInput(this.inputEditForm.value).subscribe(
      response => {
        if (response.status == true) {
          this.inputEditForm.reset();
          this.toastr.success('Modificata con successo');
          this.getData();
        }
        else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  setDeleteEntrata(member: any) {
    if (this.deleteContent) {
      this.deleteContent = null;
      this.deleteContent = member;

    } else {
      this.deleteContent = member;
    }
    this.ngOnInit();
  }

  deleteEntrata(id: any) {
    this._contabilitaService.deleteInput({ id: id }).subscribe(
      response => {
        if (response) {
          this.toastr.success('Rimozione avvenuta con successo');
          this.getData();
        }
      }
    )
  }

  addUscita() {
    this.outputAddForm.patchValue({
      data: this.getFormattedDate(this.outputAddForm.value.data, 'YYYY-MM-DD')
    });
    this._contabilitaService.addOutput(this.outputAddForm.value).subscribe(
      response => {
        if (response.status == true) {
          this.outputAddForm.reset();
          this.toastr.success('Aggiunta con successo');
          this.getData2();
        }
        else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  setEditUscita(usc: any) {
    this.outputEditForm.patchValue({
      id: usc.id,
      anagrafica_id: usc.anagrafica_id,
      intestazione: usc.intestazione,
      data: this.getFormattedDate(usc.data, 'YYYY-MM-DD'),
      ricevuta: usc.ricevuta,
      oggetto: usc.oggetto,
      uscita: usc.uscita,
      i_p: usc.i_p,
      iva_uscita: usc.iva_uscita,
      frequenza: usc.frequenza
    });
  }

  editUscita() {
    this.outputEditForm.patchValue({
      data: this.getFormattedDate(this.outputEditForm.value.data, 'YYYY-MM-DD')
    });
    this._contabilitaService.updateOutput(this.outputEditForm.value).subscribe(
      response => {
        if (response.status == true) {
          this.outputEditForm.reset();
          this.toastr.success('Modificata con successo');
          this.getData2();
        }
        else {
          this.toastr.error(response.message);
        }
      }
    );
  }

  setStudente(member: any) {
    if (this.studente_settato) {
      this.studente_settato = null;
      this.studente_settato = member;



      this._anagraficaService.getPagamentiStudente({ studente_id: member?.elenco_anagrafica?.id }).subscribe(res => {
        this.prova = res.result;
        this.saldati.next(res.result);
      })

    } else {
      this.studente_settato = member;
      this._anagraficaService.getPagamentiStudente({ studente_id: member?.elenco_anagrafica?.id }).subscribe(res => {
        this.saldati.next(res.result);
      })
    }
    this.ngOnInit();
  }

  deleteUscita(id: any) {
    this._contabilitaService.deleteOutput({ id: id }).subscribe(
      response => {
        if (response) {
          this.toastr.success('Rimozione avvenuta con successo');
          this.ngOnInit();
        }
      }
    )
  }


  delay() {
    setTimeout(function () {
      let spinner = document.getElementById('splash-screen')
      spinner.style.display = "none";
      let wrap_page = document.getElementById('wrap2')
      wrap_page.classList.remove("d-none");
    }, 500)
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  handlePage2(e: any) {
    this.currentPage2 = e.pageIndex;
    this.pageSize2 = e.pageSize;
    this.getData2();
    return e;
  }

  // funzione per formattazione custom dei numeri
  formatNumber(value: any) {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return '';
    }
    let formattedValue = numericValue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return `€${formattedValue}`;
  }

}