import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CostManagementService } from 'src/app/services/costManagement.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  dataSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tableLoaded = true;
  displayedColumns: string[] = ['testo', 'valore', 'azione'];
  currentPage = 0;
  pageSize = 10;
  pageEvent: PageEvent;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  costForm: FormGroup;
  type_cost = 1;
  isEdit = false;

  status_type: any = 1;
  type = [
    // { label: "Nessuno", value: null, selected: true },
    { label: 'Tassa iscrizione', value: 1, selected: true },
    { label: "Materiale didattico", value: 2, selected: false }
  ];

  constructor(
    private fb: FormBuilder,
    private costService: CostManagementService,
    private toastr: ToastrService,
  ) {
    this.costForm = this.fb.group({
      id: [null],
      testo_scontistica: [null],
      valore: [null],
      type: [null],
      like_search: [null]
    })
  }

  ngOnInit(): void {
    this.getTableData();
  }

  /**
   * Richiama i dati nella tabella
   */
  getTableData() {
    let params = {
      skip: this.currentPage * this.pageSize,
      take: this.pageSize,
      type: this.type_cost,
      like_search: null,
    };
    if (this.costForm.get('like_search').value) {
      params.like_search = this.costForm.get('like_search').value;
    }
    
    this.costService.getCostTable(params).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.dataSource.next(res.result);
      this.records.next(res.total);
    });
  }

  /**
   * Si attiva al cambio del tab, torna i dati nella tabella a seconda del tipo
   * @param element 
   */
  setType(element) {
    this.type_cost = element;
    this.getTableData()
  }

  /**
   * Gestisce i dati all'apertura della modale
   * @param data 
   */
  openModal(data?: any) {
    if (data) {
      this.isEdit = true;
      this.costForm.patchValue({
        id: data.id,
        testo_scontistica: data.testo_scontistica,
        valore: data.valore,
        type: data.type
      }); 
    } else {
      this.isEdit = false;
      this.costForm.reset();
    }
  }

  /**
   * Setta i dati della modale Delete
   * @param data 
   */
  setModaldelete(data) {
    this.costForm.patchValue({
      id: data.id,
      testo_scontistica: data.testo_scontistica,
      valore: data.valore,
      type: data.type
    }); 
  }

  /**
   * Gestisce il salvataggio e la modifica dei dati
   */
  saveCost() {
    if (this.isEdit) {      
      this.costService.updateCost(this.costForm.value.id, this.costForm.value).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.toastr.success(res.message);
        this.getTableData();

      }, error => {
        this.toastr.error(error?.message);
      });
    } else {
      this.costService.addCost(this.costForm.value).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.toastr.success(res.message);
        this.getTableData();
      }, error => {
        this.toastr.error(error?.message);
      });
    }

    
  }

  /**
   * Richiamato nella paginazione
   * @param e 
   * @returns 
   */
  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getTableData();
    return e;
  }

  /**
   * Eliminazione dei dati
   */
  deleteElement() {
    this.costService.deleteCost(this.costForm.value.id).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.toastr.success(res.message);
      this.costForm.reset();
      this.getTableData();
    }, error => {
      this.toastr.error(error?.message);
    });
  }

}
