import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ClasseService } from 'src/app/services/class.service';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, Params } from '@angular/router';



@Component({
  selector: 'app-registro-anagrafica',
  templateUrl: './registro-anagrafica.component.html',
  styleUrls: ['./registro-anagrafica.component.scss']
})
export class RegistroAnagraficaComponent implements OnInit {



  filter: any;
  students: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  displayedColumns: string[] = ['name_surname', 'presenza', 'note_studente', 'azione'];
  currentPage = 0;
  pageSize = 10;
  pageEvent: PageEvent;
  giorno: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  docente_id: any;
  nome: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  cognome: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  studentis: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  registro_id;
  delete_id;

  formAddStudente: FormGroup;
  formEditStudente: FormGroup;
  visualizzaStudenti: boolean;

  constructor(private class_service: ClasseService, private anagrafica_service: AnagraficaService, private fb: FormBuilder, private toastr: ToastrService,
    private activatedRoute: ActivatedRoute, private router: Router
  ) {

    this.formAddStudente = this.fb.group({
      registro_id: null,
      anagrafica_id: null,
      note_studente: null,
      presenza: null,
      search_student: null
    });

    this.formEditStudente = this.fb.group({
      registro_id: null,
      anagrafica_id: null,
      note_studente: null,
      presenza: null,
    });

  }


  ngOnInit(): void {
    this.getUrlParams();
    this.class_service.getSingleRegistro({ id: this.registro_id }).subscribe(res => {
      this.giorno.next(res.result.giorno);
      this.docente_id = res.result.evento_rel.docente_id;
      if (this.docente_id != null) {
        this.anagrafica_service.getAnagraficaById({ id: this.docente_id }).subscribe(res => {
          this.nome.next(res.result.name);
          this.cognome.next(res.result.surname)
        })
      }
      this.getData();
    })
  }

  getUrlParams() {
    let id = this.router.url.split("/")[2];
    this.registro_id = id;
  }

  getData() {
    this.class_service.getRegistroAnagrafica({ id: this.registro_id, skip: this.pageSize * this.currentPage, take: this.pageSize }).subscribe(res => {
      this.students.next(res.result);
      this.records.next(res.total);
    })
  }



  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }


  //FUNZIONE CHE AGGIUNGE UNO STUDENTE//
  addStudent() {
    this.formAddStudente.patchValue({
      registro_id: this.registro_id,
    })
    this.class_service.saveUpdateRegistroAnagrafica(this.formAddStudente.value).subscribe(res => {
      this.toastr.success("Elemento aggiunto con successo")
      this.formAddStudente.reset();
      this.getData();
    })
  }

  //AL CLICK 
  setEdit(element) {
    this.formEditStudente.patchValue({
      anagrafica_id: element.studenti[0].id,
      note_studente: element.note_studente,
      presenza: element.presenza_studente
    })
  }

  deleteModal(element) {
    this.delete_id = element.id;
  }

  //ELIMINA LO STUDENTE//
  deleteMember(id) {
    this.class_service.DeleteRegistroAnagrafica({ id: id }).subscribe(res => {
      this.toastr.success("Elemento eliminato con successo");
      this.getData();
    })
  }

  //CERCA LO STUDENTE AUTOCOMPLETE//
  searchStudents() {
    let params = { like_search: this.formAddStudente.value.search_student, representatives: true };
    this.anagrafica_service.searchStudents(params).subscribe(response => {
      if (response.result) {
        this.visualizzaStudenti = true;
      }
      this.studentis.next(response.result);
    })
  }

  //SETTA LO STUDENTE//
  setStudents(stud) {
    this.formAddStudente.patchValue({
      anagrafica_id: stud.id,
      search_student: stud.name + " " + stud.surname
    })
    this.visualizzaStudenti = false
  }


  editStudente() {
    this.formEditStudente.patchValue({
      registro_id: this.registro_id,
    })
    this.class_service.saveUpdateRegistroAnagrafica(this.formEditStudente.value).subscribe(res => {
      this.toastr.success("Elemento modificato con successo")
      this.formEditStudente.reset();
      this.getData();
    })
  }


}
