import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'password-criterias',
  templateUrl: './password-criterias.component.html',
  styleUrls: ['./password-criterias.component.scss']
})
export class PasswordCriteriasComponent implements OnInit {
  @Input() control: AbstractControl;
  password_errors = {
    lowercase: true,
    uppercase: true,
    special: true,
    number: true,
    length: true
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe({
      next: value => {
        this.password_errors.lowercase = value ? !value.match(/[a-z]/g) : true; //lettera minuscola
        this.password_errors.uppercase = value ? !value.match(/[A-Z]/g) : true; //lettera maiuscola
        this.password_errors.number = value ? !value.match(/\d/g) : true; //numero
        this.password_errors.special = value ? !value.match(/[~<>|_,."'(){}[\]#=+?!@$%^&*-]/g) : true; //carattere speciale
        this.password_errors.length = value ? value.length < 8 : true; //8 caratteri
      }
    })
  }
}

