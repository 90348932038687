import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ClasseService } from 'src/app/services/class.service';
import { ToastrService } from 'ngx-toastr';
import { ConversationService } from 'src/app/services/conversation.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { isAdminOrStaff } from 'src/app/utilities/users-utilities';
@Component({
  selector: 'app-conversation-details',
  templateUrl: './conversation-details.component.html',
  styleUrls: ['./conversation-details.component.scss']
})
export class ConversationDetailsComponent implements OnInit {
  adminOrStaff = isAdminOrStaff();
  data: any;
  class_id: any;
  docente_id: any;
  class_details: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  displayedColumns: string[] = ['nome', 'email', 'telefono', 'ore_acquistate', 'ore_svolte', 'data_iscrizione', 'azione'];
  currentPage = 0;
  pageSize = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  pageEvent: PageEvent;
  students: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  registro: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  id_class_docente: null;
  visualizzaStudenti = false;
  studentis: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  classes: any;
  filter = null;
  formAddDocente: FormGroup;
  formEditDocente: FormGroup;
  formAddStudente: FormGroup;
  formEditStudente: FormGroup;
  deleteContent: any;
  studenti_array: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  student_arrays = new Array();
  docent_arrays = new Array();
  docente_array: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  docente: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  docente_form_id: any;
  user_id: string;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tutti = { id: 0, name: 'Tutti', surname: '' };
  item: any;

  constructor(
    private _class: ClasseService,
    private fb: FormBuilder,
    private _anagraficaService: AnagraficaService,
    private _classeService: ClasseService,
    private _conversationService: ConversationService,
    private toastr: ToastrService,
    private router: Router,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('it-IT');
    this.formAddDocente = this.fb.group({
      conversation_id: null,
      docente_id: null
    }),
      this.formEditDocente = this.fb.group({
        id: null,
        conversation_id: null,
        docente_id: null
      }),
      this.formAddStudente = this.fb.group({
        conversation_id: null,
        studente_id: [null, Validators.required],
        ore_acquistate: [null, Validators.required],
        ore_svolte: [null, Validators.required],
        data_iscrizione: [moment().format('YYYY-MM-DD'), Validators.required],
        search_student: [null]
        // moment().locale('it').add(1, 'days').toDate();
      }),
      this.formEditStudente = this.fb.group({
        id: null,
        ore_acquistate: [null, Validators.required],
        ore_svolte: [null, Validators.required],
        data_iscrizione: [moment().format('YYYY-MM-DD'), Validators.required]
      })
  }

  ngOnInit(): void {
    this.user_id = localStorage.getItem('user_id');
    this._anagraficaService.getUserId({ user_id: this.user_id }).subscribe(res => {
      this.ruolo.next(res.result?.role);
    });
    this.class_id = this.router.url.split('/')[2];
    //INFO SULLA CONVESATION//
    this._conversationService.getConversationById({ id: this.class_id }).subscribe(response => {
      this.class_details.next(response.result);
      this._conversationService.getDocentByConversationId({ conversation_id: this.class_id }).subscribe(response => {
        this.docente_form_id = response.result?.id;
        this.docente_id = response.result?.docente.id;
        this.docente.next(response.result?.docente);
      })
      this.getRegistro();
    })

    //OTTENGO STUDENTI//
    this._anagraficaService.getStudent().subscribe(response => {
      this.student_arrays = response.result;
      this.formAddStudente.value.studente_id = this.student_arrays[0].id;
      response.result.unshift(this.tutti)
      this.studenti_array.next(response.result);
    })
    //OTTENGO DOCENTI//
    this._anagraficaService.getDocents().subscribe(response => {
      this.docent_arrays = response.result;
      this.formAddDocente.value.docente_id = this.docent_arrays[0].id;
      this.docente_array.next(response.result);
    })
    this.getData();
  }

  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  setDeleteElement(element) {
    this.deleteContent = element;
  }

  getData() {
    let params = {
      conversation_id: this.class_id,
      like_search: this.filter,
      start: this.currentPage * this.pageSize,
      lenght: this.pageSize
    }
    this._conversationService.getStudentByConversationId(params).subscribe(response => {
      if (response.result) {
        this.records.next(response.total);
        this.students.next(response.result);
      }
    });
  }

  searchStudents() {
    let params = { like_search: this.formAddStudente.value.search_student, representatives: true };
    this._anagraficaService.searchStudents(params).subscribe(response => {
      if (response.result) {
        this.visualizzaStudenti = true;
      }
      this.studentis.next(response.result);
    })
  }

  setStudents(stud) {
    this.formAddStudente.patchValue({
      studente_id: stud.id,
      search_student: stud.name + " " + stud.surname
    })
    this.visualizzaStudenti = false
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  addDocente() {
    if (this.formAddDocente.value.docente_id == null) {
      this.toastr.error("Seleziona il docente");
      return;
    }

    this.formAddDocente.value.conversation_id = this.class_id;
    this._conversationService.addMemberToConversation(this.formAddDocente.value).subscribe(response => {
      if (response.result) {
        this.formAddDocente.reset();
        this.toastr.success("Docente Aggiunto con successo");
        this.ngOnInit();
      }
    }, error => {
      this.toastr.error(error.error.message);
    })
  }

  setDocente() {
    this.formEditDocente.patchValue({
      id: this.docente_form_id,
      conversation_id: this.class_id,
      docente_id: this.docente_id
    })
  }

  editDocente() {
    this._conversationService.editMemberToConversation(this.formEditDocente.value).subscribe(response => {
      if (response.result) {
        this.formEditDocente.reset();
        this.formAddDocente.reset();
        this.toastr.success("Docente aggiornato con successo");
        this.ngOnInit();
      }
    }, error => {
      this.toastr.error(error.error.message);
    })
  }

  addStudent() {
    this.formAddStudente.patchValue({
      date: this.getFormattedDate(this.formAddStudente.value.data_iscrizione, 'YYYY-MM-DD'),
      conversation_id: this.class_id
    });
    // this.formAddStudente.value.conversation_id = this.class_id;
    if (this.formAddStudente.value.studente_id == null || this.formAddStudente.value.studente_id == 0) {
      this.toastr.error("Seleziona uno studente valido");
      return;
    }
    this._conversationService.addMemberToConversation(this.formAddStudente.value).subscribe(response => {
      this.toastr.success("Studente Aggiunto con successo");
      this.formAddStudente.reset();
      this.ngOnInit();
    }, error => {
      this.toastr.error(error.error.message);
    })
  }

  setEdit(classe: any) {
    let text = classe.data_iscrizione;
    // let result = text?.substring(0, 10);
    this.formEditStudente.patchValue({
      id: classe.id,
      ore_acquistate: classe.ore_acquistate,
      ore_svolte: classe.ore_svolte,
      data_iscrizione: this.getFormattedDate(classe.data_iscrizione, 'YYYY-MM-DD')
    })
  }

  deleteStudent(id) {
    this._conversationService.deletMemberToConversation({ id: id }).subscribe(res => {
      this.toastr.success("Studente eliminato con successo");
      this.ngOnInit();
    })
  }

  editStudente() {
    // this.formEditStudente.patchValue({
    //   date: this.getFormattedDate(this.formEditStudente.value.data_iscrizione, 'YYYY-MM-DD')
    // });
    this._conversationService.editMemberToConversation(this.formEditStudente.value).subscribe(res => {
      this.toastr.success('Studente aggiornato con successo');
      this.ngOnInit();
    })
  }

  editClassTable() {
    this._classeService.editClassTable(this.formEditStudente.value).subscribe(
      response => {
        if (response.result) {
          //METTERE IL TOST E FARE REFRESH//
          this.toastr.success('Classe aggiornata con successo');
          this.formAddStudente.reset();
          this.getData();
        }
      }
    )
  }

  gotoFunzione(element) {
    this.router.navigate(['/anagrafica/info-anagrafica/' + element.studente_id]);
  }

  goTo(item) {
    this.router.navigate(['/registro/' + item.id]);
  }

  getRegistro() {
    this._classeService.getRegistroConveration({ classe_id: this.class_id }).subscribe(response => {
      let ora;
      for (let i = 0; i < response.result.length; i++) {
        let ora_inizio = new Date(response.result[i].evento_rel.start);
        let ora_fine = new Date(response.result[i].evento_rel.end);
        let ora = ora_fine.getTime() - ora_inizio.getTime();
        ora = (ora) / (3.6 * 1000000);
        response.result[i].durata = ora;
      }
      this.registro.next(response.result)
    })
  }


  editRegistro(event) {
    let scelta = event.target.checked;
    this._classeService.editRegistro({ id: this.item.id, presenza: scelta }).subscribe(response => {
      if (response.message == "Non puoi modificare una lezione non svolta") {
        this.toastr.error(response.message)
      } else {
        this.toastr.success(response.message);
      }
      this.getRegistro();
    })
  }

  setItemRegistro(item) {
    this.item = item;
  }




}
