<div class="altezza-page px-0 mx-0" id="wrap2">
    <div class="row px-0 mx-0">
        <div class="col-md-2 px-0 mx-0">
            <aside class="bg-color">
                <div class="margine-sup-side padding-logo">
                    <a routerLink="/dashboard">
                        <img class="h-25px logo" src="./assets/img/logo-yeschool-1.svg" alt="logo-yeschool-1">
                    </a>
                </div>
                <div class="menu-item padding-logo">
                    <div class="menu-item padding-logo">
                        <h4 class="section__tab section_text"> Contabilità </h4>
                    </div>
                    <div class="menu-item ">
                        <div class="row">
                            <div class="col-11">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item" style="border:none">
                                        <h2 class="accordion-header" id="headingOne">
                                            <span class="accordion-button" style="background: #f5f8fa73; height: 40px;"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                Filtra per date
                                            </span>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <ng-container>
                                                    <div class="col-12 mt-3 mb-2">

                                                        <form [formGroup]="dateForm">
                                                            <div class="form-group">
                                                                <label for="inputSurname"><b>Data inizio</b></label>
                                                                <mat-form-field appearance="outline"
                                                                    class="col-12 no_padding">
                                                                    <input matInput [matDatepicker]="data_inizio"
                                                                        formControlName="data_inizio"
                                                                        (dateChange)="onStartDateChange()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="data_inizio"></mat-datepicker-toggle>
                                                                    <mat-datepicker #data_inizio></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="form-group mt-2">
                                                                <label for="inputSurname"><b>Data fine</b></label>
                                                                <mat-form-field appearance="outline"
                                                                    class="col-12 no_padding">
                                                                    <input matInput [matDatepicker]="data_fine"
                                                                        formControlName="data_fine" [min]="minEndDate">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="data_fine"></mat-datepicker-toggle>
                                                                    <mat-datepicker #data_fine></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <button type="button" (click)="resetDate()"
                                                                    class="btn btn-danger my-3 px-2 py-1"
                                                                    data-dismiss="modal"> Reset </button>
                                                                <button type="button" (click)="setDate()"
                                                                    class="btn color-butt my-3 px-2 py-1"
                                                                    data-dismiss="modal"> Filtra </button>
                                                            </div>
                                                        </form>

                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <div class="col-md-10 margine-sup">

            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0">Contabilità</h1>
                    </div>
                    <div class="col-4 d-flex justify-content-end"></div>
                </div>
            </div>

            <div class="section__body">
                <div class="row mt-4"></div>
                <!--SEZIONE TAB-->
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button *ngIf="flag0==null && flag1==null" class="nav-link active" id="nav-all-tab"
                            data-bs-toggle="tab" data-bs-target="#nav-all" (click)="setFlagnull()" type="button"
                            role="tab" aria-controls="nav-all" aria-selected="true">Tutti</button>
                        <button *ngIf="flag0 || flag1" class="nav-link" id="nav-all-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-all" (click)="setFlagnull()" type="button" role="tab"
                            aria-controls="nav-all" aria-selected="true">Tutti</button>

                        <button *ngIf="flag0" class="nav-link-active" id="nav-input-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-input" type="button" role="tab" aria-controls="nav-input"
                            (click)="setFlag0()" aria-selected="true">Entrate</button>
                        <button *ngIf="flag0==null" class="nav-link" id="nav-input-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-input" type="button" role="tab" aria-controls="nav-input"
                            (click)="setFlag0()" aria-selected="true">Entrate</button>

                        <button *ngIf="flag1" class="nav-link active" id="nav-output-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-output" (click)="setFlag1()" type="button" role="tab"
                            aria-controls="nav-output" aria-selected="true">Uscite</button>
                        <button *ngIf="flag1==null" class="nav-link" id="nav-output-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-output" (click)="setFlag1()" type="button" role="tab"
                            aria-controls="nav-output" aria-selected="true">Uscite</button>
                    </div>
                </nav>
                <!--FINE SEZIONE TAB-->
                <div class="tab-content" id="nav-tabContent">
                    <!--TAB TUTTI-->
                    <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">

                        <h1 class="my-2">Tutti</h1>

                        <div class="row my-4 justify-content-between">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per intestazione o studente" [(ngModel)]="filter"
                                    (keyup)="getData()" class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    data-target="#addInput" (click)="openModal()">
                                    Aggiungi transazione
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records | async) == 0">
                            <div class="no-cont py-4"><i class="f-16">Nessun risultato riscontrato</i> </div>
                        </div>

                        <table *ngIf="(records | async) > 0" mat-table [dataSource]="(entrate|async)" class="tb-bord">
                            <!-- Intestazione Column -->
                            <ng-container matColumnDef="intestazione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Intestazione
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.intestazione}}</td>
                            </ng-container>

                            <!-- Nome Column -->
                            <ng-container matColumnDef="nome">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Nome e
                                    Cognome </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.elenco_anagrafica?.name}}
                                    {{element.elenco_anagrafica?.surname}}</td>
                            </ng-container>

                            <!-- Data Column -->
                            <ng-container matColumnDef="data">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Data </th>
                                <td mat-cell *matCellDef="let element">{{element.data | date:'dd/MM/YYYY'}}</td>
                            </ng-container>





                            <!-- Entrata Column -->
                            <ng-container matColumnDef="entrata">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Importo</th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element?.flag == 0"><i class="bi bi-plus-circle-fill text-success"></i>
                                        {{formatNumber(element?.entrata)}}</span>
                                    <span *ngIf="element?.flag == 1"><i class="bi bi-dash-circle-fill text-danger"></i>
                                        {{formatNumber(element?.entrata)}}</span>
                                    <!-- {{(element.entrata | currency: 'EUR':'symbol')?.replace('.',',')}} -->
                                </td>

                            </ng-container>

                            <!-- Stato Column -->
                            <ng-container matColumnDef="stato">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Stato </th>
                                <td mat-cell *matCellDef="let element">{{element.stato}}</td>
                            </ng-container>




                            <!-- Azione Column -->
                            <ng-container matColumnDef="azione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef>
                                    <span>Azione</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn btn-outline-primary" data-toggle="modal" data-target="#viewData"
                                        (click)="setStudente(element)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-dark" *ngIf="element.stato != 'Saldato'"
                                        (click)="setEditEntrata(element)" data-toggle="modal" data-target="#editInput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-danger" (click)="setDeleteEntrata(element)"
                                        data-toggle="modal" data-target="#deleteInput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" style="text-align: center;"
                                    [attr.colspan]="displayedColumns.length">Nessun elemento trovato
                                </td>
                            </tr>
                        </table>

                        <mat-paginator [length]="records | async" [pageSize]="pageSize"
                            [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent = handlePage($event)"
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>

                    <!--TAB ENTRATE-->
                    <div class="tab-pane fade" id="nav-input" role="tabpanel" aria-labelledby="nav-input-tab">

                        <h1 class="my-2">Entrate</h1>

                        <div class="row my-4 justify-content-between">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per intestazione o studente" [(ngModel)]="filter"
                                    (keyup)="getData()" class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    data-target="#addInput" (click)="openModal()">
                                    Aggiungi transazione
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records | async) == 0">
                            <div class="no-cont py-4"><i class="f-16">Nessun risultato riscontrato</i> </div>
                        </div>

                        <table *ngIf="(records | async) > 0" mat-table [dataSource]="(entrate|async)" class="tb-bord">
                            <!-- Intestazione Column -->
                            <ng-container matColumnDef="intestazione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Intestazione
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.intestazione}}</td>
                            </ng-container>

                            <!-- Nome Column -->
                            <ng-container matColumnDef="nome">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Nome e
                                    Cognome </th>
                                <td mat-cell *matCellDef="let element">{{element.elenco_anagrafica?.name}}
                                    {{element.elenco_anagrafica?.surname}}</td>
                            </ng-container>

                            <!-- Data Column -->
                            <ng-container matColumnDef="data">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Data </th>
                                <td mat-cell *matCellDef="let element">{{element.data | date:'dd/MM/YYYY'}}</td>
                            </ng-container>



                            <!-- Entrata Column -->
                            <ng-container matColumnDef="entrata">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Importo</th>
                                <td mat-cell *matCellDef="let element">
                                    <!-- {{(element.entrata | currency: 'EUR':'symbol')?.replace('.',',')}} -->
                                    {{formatNumber(element?.entrata)}}
                                </td>
                            </ng-container>

                            <!-- Stato Column -->
                            <ng-container matColumnDef="stato">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Stato </th>
                                <td mat-cell *matCellDef="let element">{{element.stato}}</td>
                            </ng-container>





                            <!-- Azione Column -->
                            <ng-container matColumnDef="azione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef>
                                    <span>Azione</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn btn-outline-primary" data-toggle="modal" data-target="#viewData"
                                        (click)="setStudente(element)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-dark" *ngIf="element.stato != 'Saldato'"
                                        (click)="setEditEntrata(element)" data-toggle="modal" data-target="#editInput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-danger" (click)="setDeleteEntrata(element)"
                                        data-toggle="modal" data-target="#deleteInput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" style="text-align: center;"
                                    [attr.colspan]="displayedColumns.length">Nessun elemento trovato
                                </td>
                            </tr>
                        </table>

                        <mat-paginator [length]="records | async" [pageSize]="pageSize"
                            [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent = handlePage($event)"
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>

                    <!--TAB USCITE-->
                    <div class="tab-pane fade" id="nav-output" role="tabpanel" aria-labelledby="nav-output-tab">

                        <h1 class="my-2">Uscite</h1>

                        <!-- <div class="row my-4">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per intestazione" [(ngModel)]="filter2" (keyup)="getData2()"
                                    class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-6"></div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    data-target="#addOutput">
                                    Aggiungi transazione
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records2 | async) == 0">
                            <i><span class="f-16">Nessun risultato riscontrato</span></i>
                        </div>

                        <table mat-table [dataSource]="(uscite|async)" class="tb-bord">
                            
                            <ng-container matColumnDef="intestazione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Intestazione
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.intestazione}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="nome">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Nome e
                                    Cognome </th>
                                <td mat-cell *matCellDef="let element">{{element.output_anagrafica?.name}}
                                    {{element.output_anagrafica?.surname}}</td>
                            </ng-container>

                           
                            <ng-container matColumnDef="data">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Data </th>
                                <td mat-cell *matCellDef="let element">{{element.data | date:'dd/MM/YYYY'}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="ricevuta">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Ricevuta
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.ricevuta}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="oggetto">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Oggetto </th>
                                <td mat-cell *matCellDef="let element">{{element.oggetto}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="uscita">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Uscite</th>
                                <td mat-cell *matCellDef="let element">{{element.uscita}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="ip">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> I/P</th>
                                <td mat-cell *matCellDef="let element">{{element.i_p}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="mezzo">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Mezzo</th>
                                <td mat-cell *matCellDef="let element">{{element.mezzo}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="iva_u">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Iva uscite
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.iva_uscita}}</td>
                            </ng-container>

                            
                            <ng-container matColumnDef="azione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef>
                                    <span>Azione</span></th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn btn-outline-danger" (click)="setDeleteUscita(element)"
                                        data-toggle="modal" data-target="#deleteOutput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-dark" (click)="setEditUscita(element)"
                                        data-toggle="modal" data-target="#editOutput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" style="text-align: center;"
                                    [attr.colspan]="displayedColumns2.length">Nessun elemento trovato
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [length]="records2 | async" [pageSize]="pageSize2"
                            [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent2 = handlePage2($event)"
                            showFirstLastButtons>
                        </mat-paginator> -->

                        <div class="row my-4 justify-content-between">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per intestazione o studente" [(ngModel)]="filter"
                                    (keyup)="getData()" class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                    data-target="#addInput" (click)="openModal()">
                                    Aggiungi transazione
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records | async) == 0">
                            <div class="no-cont py-4"><i class="f-16">Nessun risultato riscontrato</i> </div>
                        </div>

                        <table *ngIf="(records | async) > 0" mat-table [dataSource]="(entrate|async)" class="tb-bord">
                            <!-- Intestazione Column -->
                            <ng-container matColumnDef="intestazione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Intestazione
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.intestazione}}</td>
                            </ng-container>

                            <!-- Nome Column -->
                            <ng-container matColumnDef="nome">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Nome e
                                    Cognome </th>
                                <td mat-cell *matCellDef="let element">{{element.elenco_anagrafica?.name}}
                                    {{element.elenco_anagrafica?.surname}}</td>
                            </ng-container>

                            <!-- Data Column -->
                            <ng-container matColumnDef="data">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Data</th>
                                <td mat-cell *matCellDef="let element">{{element.data | date:'dd/MM/YYYY'}}</td>
                            </ng-container>


                            <!-- Entrata Column -->
                            <ng-container matColumnDef="entrata">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Importo</th>
                                <td mat-cell *matCellDef="let element">
                                    <!-- {{(element.entrata | currency: 'EUR':'symbol')?.replace('.',',')}} -->

                                    {{formatNumber(element?.entrata)}}
                                </td>
                            </ng-container>

                            <!-- Stato Column -->
                            <ng-container matColumnDef="stato">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Stato </th>
                                <td mat-cell *matCellDef="let element">{{element.stato}}</td>
                            </ng-container>




                            <!-- Iva Column -->
                            <ng-container matColumnDef="iva_e">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Iva
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.iva_entrata}}</td>
                            </ng-container>

                            <!-- Azione Column -->
                            <ng-container matColumnDef="azione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef>
                                    <span>Azione</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn btn-outline-primary" data-toggle="modal" data-target="#viewData"
                                        (click)="setStudente(element)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-dark" *ngIf="element.stato != 'Saldato'"
                                        (click)="setEditEntrata(element)" data-toggle="modal" data-target="#editInput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-outline-danger" (click)="setDeleteEntrata(element)"
                                        data-toggle="modal" data-target="#deleteInput">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" style="text-align: center;"
                                    [attr.colspan]="displayedColumns.length">Nessun elemento trovato
                                </td>
                            </tr>
                        </table>

                        <mat-paginator [length]="records | async" [pageSize]="pageSize"
                            [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent = handlePage($event)"
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal View Member -->
<div class="modal fade" id="viewData" tabindex="-1" role="dialog" aria-labelledby="viewDataLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="modal-title" id="viewMemberLabel">Dettaglio Rata</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!--DETTAGLIO RATA-->
                <div class="row mt-3">
                    <div class="col-6">
                        <p class="f-17" *ngIf="studente_settato?.flag==0"><b>Entrata</b></p>
                        <p class="f-17" *ngIf="studente_settato?.flag==1"><b>Uscita</b></p>
                    </div>
                    <div class="col-6">
                        <p class="f-17"> <b>Importo:</b> {{(studente_settato?.entrata | currency:
                            'EUR':'symbol')?.replace('.',',')}}</p>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-6">
                        <p class="f-17"><b>Intestazione:</b> {{studente_settato?.intestazione}}</p>
                    </div>
                    <div class="col-6">
                        <p class="f-17"> <b>Oggetto:</b> {{studente_settato?.oggetto}}</p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <p class="f-17"><b>Data:</b> {{studente_settato?.data|date:'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-6">
                        <p class="f-17"> <b>Stato:</b> {{studente_settato?.stato}}</p>
                    </div>
                </div>
                <div *ngIf="studente_settato?.elenco_anagrafica!=null">
                    <h3 class="mt-4">Dettaglio Studente</h3>
                    <div class="row mt-3">
                        <div class="col-6">
                            <p class="f-17"><b>Nome:</b> {{studente_settato?.elenco_anagrafica?.name}}</p>
                        </div>
                        <div class="col-6">
                            <p class="f-17"> <b>Cognome:</b> {{studente_settato?.elenco_anagrafica?.surname}}</p>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-6">
                            <p class="f-17"><b>Email: </b> <span
                                    *ngIf="studente_settato?.elenco_anagrafica?.email == null">Email mancante</span>
                                <span
                                    *ngIf="studente_settato?.elenco_anagrafica?.email != null">{{studente_settato?.elenco_anagrafica?.email}}</span>
                            </p>
                        </div>
                        <div class="col-6">
                            <p class="f-17"> <b>Telefono: </b><span
                                    *ngIf="studente_settato?.elenco_anagrafica?.phone == null">Telefono mancante</span>
                                <span
                                    *ngIf="studente_settato?.elenco_anagrafica?.phone != null">{{studente_settato?.elenco_anagrafica?.phone}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <p class="f-17"><b>Data di nascita:</b> {{studente_settato?.elenco_anagrafica?.birth_date |
                                date:'dd/MM/YYYY'}}</p>
                        </div>
                    </div>
                </div>

                <div *ngIf="studente_settato?.contratto!=null">
                    <h3 class="mt-4">Dettaglio pagamenti Contratto</h3>
                    <table class="demo mt-4">
                        <thead>
                            <tr>
                                <th>Rata</th>
                                <th>Importo</th>
                                <th>Data pagamento</th>
                                <th>Saldato</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of (saldati | async)">
                                <td>{{item.rata}}</td>
                                <td>{{item.importo}}</td>
                                <td>
                                    <span *ngIf="item.data_pagamento == null">Non presente </span>
                                    {{item.data_pagamento}}
                                </td>
                                <td>
                                    <span *ngIf="item.pagato == '1'">Si</span>
                                    <span *ngIf="item.pagato == '0'">No</span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="pt-4">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal Add Input  -->
<div class="modal text-left fade" id="addInput" tabindex="-1" role="dialog" aria-labelledby="addInputModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 *ngIf="inputAddForm.get('flag').value == null">Aggiungi transazione</h2>
                <h2 *ngIf="inputAddForm.get('flag').value == 0">Aggiungi
                    transazione entrata</h2>
                <h2 *ngIf="inputAddForm.get('flag').value == 1">Aggiungi transazione uscita</h2>
                <button type="button" (click)="resetModal()" class="btn-close" data-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="inputAddForm">
                    <h3> Dati </h3>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputflag">Entrata / Uscita</label>
                                <select class="form-select form-select-lg" aria-label="inputflag"
                                    formControlName="flag">
                                    <option *ngFor="let value of inOutFlag" value="{{value.valore}}">{{value.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputIntestazione"><b>Intestazione</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="intestazione">
                            </div>
                        </div>
                        <div class="col-4">

                            <label for="inputName">Nome e Cognome</label>

                            <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                                placeholder="Cerca per nome o cognome" (keyup)="searchAll()"
                                formControlName="search_member">

                            <div class="container" *ngIf="visualizzaTutti" class="boxautocomplete">
                                <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                                    *ngFor="let item of (tutti | async)" style="padding:20px;" (click)="setMember(item)"
                                    onkeydown="">
                                    <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                        {{item.name}} {{item.surname}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="ast" for="inputSurname"><b>Data</b></label>
                                <mat-form-field appearance="outline" class="col-12 no_padding">
                                    <input matInput readonly [matDatepicker]="data" formControlName="data">
                                    <mat-datepicker-toggle matSuffix [for]="data">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #data></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>Ricevuta</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="ricevuta">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Oggetto</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="oggetto">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputEmail"><b>Importo</b></label>
                                <input type="number" class="form-control form-control-lg" formControlName="entrata">
                            </div>
                        </div>
                        <!-- <div class="col-4" *ngIf="inputAddForm.get('flag').value == 1">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputEmail"><b>Uscita</b></label>
                                <input type="number" class="form-control form-control-lg" formControlName="entrata">
                            </div>
                        </div> -->
                    </div>

                    <div class="row">
                        <!-- <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>I/P</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="i_p">
                            </div>
                        </div> -->
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputMezzo"><b>Stato</b></label>
                                <select class="form-select form-select-lg" aria-label="inputflag"
                                    formControlName="stato">
                                    <option *ngFor="let value of stati" value="{{value.id}}">{{value.label}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Iva Entrata</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="iva_entrata">
                            </div>
                        </div> -->
                    </div>
                    <p><i>I campi contrassegnati <span style="color: red">*</span> sono obbligatori</i></p>
                    <div class="d-flex justify-content-end">
                        <div class="pt-4">
                            <button type="button" [disabled]="!inputAddForm.valid" (click)="addEntrata()"
                                class=" btn color-butt my-3" data-dismiss="modal">
                                Salva
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal Edit Input  -->
<div class="modal text-left fade" id="editInput" tabindex="-1" role="dialog" aria-labelledby="editInputModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 *ngIf="inputEditForm.get('flag').value == 0 || inputEditForm.get('flag').value == null">Modifica
                    Transazione Entrata</h2>
                <h2 *ngIf="inputEditForm.get('flag').value == 1">Modifica Transazione Uscita</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="inputEditForm">
                    <h3> Dati </h3>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputflag">Entrata / Uscita</label>
                                <select class="form-select form-select-lg" aria-label="inputflag"
                                    formControlName="flag">
                                    <option *ngFor="let value of inOutFlag" value="{{value.valore}}">{{value.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputIntestazione"><b>Intestazione</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="intestazione">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="ast" for="inputSurname"><b>Data</b></label>
                                <mat-form-field appearance="outline" class="col-12 no_padding">
                                    <input matInput readonly [matDatepicker]="data2" formControlName="data">
                                    <mat-datepicker-toggle matSuffix [for]="data2">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #data2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>Ricevuta</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="ricevuta">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Oggetto</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="oggetto">
                            </div>
                        </div>
                        <div class="col-4"
                            *ngIf="inputEditForm.get('flag').value == 0 || inputEditForm.get('flag').value == null">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputEmail"><b>Importo</b></label>
                                <input type="number" class="form-control form-control-lg" formControlName="entrata">
                            </div>
                        </div>
                        <div class="col-4" *ngIf="inputEditForm.get('flag').value == 1">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputEmail"><b>Importo</b></label>
                                <input type="number" class="form-control form-control-lg" formControlName="entrata">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputMezzo"><b>Stato</b></label>
                                <select class="form-select form-select-lg" aria-label="inputflag"
                                    formControlName="stato">
                                    <option *ngFor="let value of stati" value="{{value.id}}">{{value.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="pt-4">
                            <button type="button" (click)="editEntrata()" [disabled]="!inputEditForm.valid"
                                class="btn color-butt my-3" data-dismiss="modal">
                                Salva
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


<!-- ! Modal Delete Input  -->
<div class="modal fade bd-example-modal-sm" id="deleteInput" tabindex="-1" role="dialog"
    aria-labelledby="deleteInputLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Rimozione</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente la transazione "{{deleteContent?.intestazione}}"?</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteEntrata(deleteContent?.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- ! Modal Add Output  -->
<div class="modal text-left fade" id="addOutput" tabindex="-1" role="dialog" aria-labelledby="addOutputModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2>Aggiungi Transazione Uscita</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="outputAddForm">
                    <h3> Dati </h3>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputIntestazione"><b>Intestazione</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="intestazione">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label for="inputName"><b>Nome</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="anagrafica_id">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label for="inputSurname"><b>Data</b></label>
                                <mat-form-field appearance="outline" class="col-12 no_padding">
                                    <input matInput readonly [matDatepicker]="dataOut" formControlName="data">
                                    <mat-datepicker-toggle matSuffix [for]="dataOut">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dataOut></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>Ricevuta</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="ricevuta">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Oggetto</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="oggetto">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Uscita</b></label>
                                <input type="number" class="form-control form-control-lg" formControlName="uscita">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>I/P</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="i_p">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Iva uscita</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="iva_uscita">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="pt-4">
                            <button mat-raised-button color="primary" [disabled]="!outputAddForm.valid"
                                (click)="addUscita()" class="color-butt my-3" data-dismiss="modal">
                                Salva
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- ! Modal Edit Output  -->
<div class="modal text-left fade" id="editOutput" tabindex="-1" role="dialog" aria-labelledby="editOutputModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2>Modifica Transazione Uscita</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="outputEditForm">
                    <h3> Dati </h3>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-3">
                                <label for="inputIntestazione"><b>Intestazione</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="intestazione">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputSurname"><b>Data</b></label>
                                <mat-form-field appearance="outline" class="col-12 no_padding">
                                    <input matInput readonly [matDatepicker]="data3" formControlName="data">
                                    <mat-datepicker-toggle matSuffix [for]="data3">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #data3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>Ricevuta</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="ricevuta">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Oggetto</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="oggetto">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Uscita</b></label>
                                <input type="number" class="form-control form-control-lg" formControlName="uscita">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>I/P</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="i_p">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Iva</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="iva_uscita">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="pt-4">
                            <button mat-raised-button color="primary" (click)="editUscita()" class="color-butt my-3"
                                data-dismiss="modal">
                                Salva
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


<!-- ! Modal Delete Output  -->
<div class="modal fade bd-example-modal-sm" id="deleteOutput" tabindex="-1" role="dialog"
    aria-labelledby="deleteOutputLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Rimozione</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3">Eliminare definitivamente la transazione "{{deleteContent2?.intestazione}}"?</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteUscita(deleteContent2?.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>