import {FormGroup, Validators} from "@angular/forms";
import * as moment from "moment/moment";

export function checkFormFields(formGroup, required_fields, unrequired_fields, reset_fields) {
    required_fields.forEach(item => {
        formGroup.get(item).setValidators(Validators.required);
        formGroup.get(item).updateValueAndValidity();
    })

    unrequired_fields.forEach(item => {
        formGroup.get(item).setValidators(null);
        formGroup.get(item).updateValueAndValidity();
    })

    reset_fields.forEach(item => {
        formGroup.get(item).reset();
    })
}

export function checkIncludedFields(formGroup: FormGroup, control: string, fields: Array<number>, exclude: boolean = false){
    let index = fields.indexOf(Number(formGroup.get(control).value));
    return exclude ? index == -1 : index != -1;
}

export function formatDateField(formGroup: FormGroup, controls: Array<string>){
    controls.forEach(control => {
        let formControl = formGroup.get(control);
        
        if(formControl.value){
            formControl.setValue(moment(formControl.value)?.locale('it').format('YYYY-MM-DD'), {emitEvent: false});
        }
    })
}